// src/components/applicationMaterials.js

import WorkIcon from "@material-ui/icons/Work";
import DescriptionIcon from "@material-ui/icons/Description";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MessageIcon from "@material-ui/icons/Message";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import SchoolIcon from "@material-ui/icons/School";
import EmailIcon from "@material-ui/icons/Email";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PersonIcon from "@material-ui/icons/Person";
import StarIcon from "@material-ui/icons/Star";
import BuildIcon from "@material-ui/icons/Build";
import BusinessIcon from "@material-ui/icons/Business";

const applicationMaterials = {
  resume: {
    title: "Resume",
    icon: WorkIcon,
  },
  coverLetter: {
    title: "Cover Letter",
    icon: DescriptionIcon,
  },
  references: {
    title: "References",
    icon: AssignmentIcon,
  },
  messageToRecruiter: {
    title: "Message to Recruiter",
    icon: MessageIcon,
  },
  linkedinProfileOptimization: {
    title: "LinkedIn Profile Optimization",
    icon: LinkedInIcon,
  },
  interviewPreparation: {
    title: "Interview Preparation",
    icon: SchoolIcon,
  },
  networkingEmailTemplates: {
    title: "Networking Email Templates",
    icon: EmailIcon,
  },
  careerDevelopmentPlan: {
    title: "Career Development Plan",
    icon: AssignmentIcon,
  },
  jobApplicationFollowUpEmail: {
    title: "Job Application Follow-Up Email",
    icon: EmailIcon,
  },
  salaryNegotiationScript: {
    title: "Salary Negotiation Script",
    icon: AttachMoneyIcon,
  },
  professionalBio: {
    title: "Professional Bio",
    icon: PersonIcon,
  },
  personalBrandingTips: {
    title: "Personal Branding Tips",
    icon: StarIcon,
  },
  skillGapAnalysis: {
    title: "Skill Gap Analysis",
    icon: BuildIcon,
  },
  companyResearchReports: {
    title: "Company Research Reports",
    icon: BusinessIcon,
  },
};

export default applicationMaterials;
