import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Root = styled(Box)(({ theme }) => ({
  "&:not(:last-child)": {
    marginBottom: theme.spacing(4),
  },
}));

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  maxWidth: 700,
  display: "inline-block",
}));

function SectionHeader({
  title,
  subtitle,
  size = 4,
  className,
  titleClassName,
  subtitleClassName,
  titleStyle,
  subtitleStyle,
  ...otherProps
}) {
  if (!title && !subtitle) return null;

  return (
    <Root component="header" className={className} {...otherProps}>
      {title && (
        <Typography
          variant={`h${size}`}
          className={titleClassName}
          gutterBottom={Boolean(subtitle)}
          sx={titleStyle}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <SubtitleTypography
          variant="subtitle1"
          className={subtitleClassName}
          sx={subtitleStyle}
        >
          {subtitle}
        </SubtitleTypography>
      )}
    </Root>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
};

export default SectionHeader;
