// src/components/DashboardSection.js

import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link, useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import useDashboardSectionStyles from "./useDashboardSectionStyles";
import GenerationsList from "./GenerationsList";
import AboutSection from "./AboutSection";
import AnalyticsSection from "./AnalyticsSection";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../util/firebase";
import { Alert } from "@material-ui/lab";

function DashboardSection(props) {
  const classes = useDashboardSectionStyles();
  const auth = useAuth();
  const router = useRouter();

  const [formData, setFormData] = useState(null);
  const [formLoading, setFormLoading] = useState(true);
  const [formError, setFormError] = useState(null);

  const [generations, setGenerations] = useState([]);
  const [loadingGenerations, setLoadingGenerations] = useState(false);
  const [generationsError, setGenerationsError] = useState(null);

  // Load form data from localStorage
  useEffect(() => {
    try {
      const savedData = localStorage.getItem("formData");
      const parsedData = savedData ? JSON.parse(savedData) : null;
      setFormData(parsedData);
    } catch (err) {
      console.error("Error retrieving form data:", err);
      setFormError("Failed to load your saved data.");
    } finally {
      setFormLoading(false);
    }
  }, []);

  // Load generations from Firestore
  useEffect(() => {
    const loadGenerationsFromFirestore = async () => {
      if (auth.user) {
        setLoadingGenerations(true);
        const userGenerationsRef = collection(
          db,
          "users",
          auth.user.uid,
          "generations"
        );
        try {
          const querySnapshot = await getDocs(userGenerationsRef);
          const loadedGenerations = [];
          querySnapshot.forEach((docSnap) => {
            const data = docSnap.data();
            // Parse date fields into JavaScript Date objects
            if (data.start) {
              data.start = data.start.toDate
                ? data.start.toDate()
                : new Date(data.start);
            }
            if (data.end) {
              data.end = data.end.toDate
                ? data.end.toDate()
                : new Date(data.end);
            }
            if (data.createdAt) {
              data.createdAt = data.createdAt.toDate
                ? data.createdAt.toDate()
                : new Date(data.createdAt);
            }
            if (data.timestamp) {
              data.timestamp = data.timestamp.toDate
                ? data.timestamp.toDate()
                : new Date(data.timestamp);
            }
            loadedGenerations.push({ id: docSnap.id, ...data });
          });
          setGenerations(loadedGenerations);
        } catch (error) {
          console.error("Error loading generations from Firestore:", error);
          setGenerationsError("Failed to load your generated content.");
        } finally {
          setLoadingGenerations(false);
        }
      }
    };

    loadGenerationsFromFirestore();
  }, [auth.user]);

  // Handle Continue Editing button click
  const handleContinueEditing = () => {
    router.push("/pro");
  };

  // Handle Start New Resume button click
  const handleStartNew = () => {
    try {
      localStorage.removeItem("formData");
      setFormData(null);
      router.push("/pro");
    } catch (err) {
      console.error("Error starting a new resume:", err);
      setFormError("Failed to start a new resume.");
    }
  };

  return (
    <Section {...props}>
      <Container className={classes.container}>
        <SectionHeader
          title="Dashboard"
          subtitle=""
          size={4}
          textAlign="center"
          className={classes.sectionHeader}
        />
        {router.query.paid && auth.user.planIsActive && (
          <Box className={classes.alertBox}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
        {/* Error Alerts */}
        {formError && (
          <Box mb={3}>
            <Alert severity="error">{formError}</Alert>
          </Box>
        )}
        {generationsError && (
          <Box mb={3}>
            <Alert severity="error">{generationsError}</Alert>
          </Box>
        )}
        {/* Loading Indicators */}
        {formLoading || loadingGenerations ? (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress aria-label="Loading" />
          </Box>
        ) : (
          <Grid container spacing={6}>
            {/* Main Content Area */}
            <Grid item xs={12} md={8}>
              <Paper className={classes.paper} elevation={0}>
                <div className={classes.welcomeMessage}>
                  <Avatar className={classes.avatar}>
                    <AccountCircle />
                  </Avatar>
                  <Typography variant="h5" className={classes.welcomeText}>
                    Welcome back
                    {formData?.personalInformation?.firstName
                      ? `, ${formData.personalInformation.firstName}`
                      : auth.user && `, ${auth.user.email}`}
                    !
                  </Typography>
                </div>

                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.aboutTypography}
                >
                  We're excited to help you craft the perfect resume and cover
                  letter tailored to your dream job.
                </Typography>

                {/* Generated Content */}
                <GenerationsList
                  auth={auth}
                  generations={generations}
                  setGenerations={setGenerations} // Add this line
                  loadingGenerations={loadingGenerations}
                />

                {/* Analytics Section */}
                <AnalyticsSection generations={generations} />
              </Paper>
            </Grid>

            {/* Sidebar Content */}
            <Grid item xs={12} md={4}>
              <AboutSection auth={auth} />
              {/* Quick Links */}
              <Box mt={3}>
                <Typography variant="h6" gutterBottom>
                  Quick Links
                </Typography>
                <div className={classes.quickLinksContainer}>
                  {formData ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.primaryButton}
                      onClick={handleContinueEditing}
                      aria-label="Continue editing your resume"
                    >
                      Pick Up Where You Left Off
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.primaryButton}
                      onClick={handleStartNew}
                      aria-label="Start a new resume"
                    >
                      Start New Resume
                    </Button>
                  )}
                  <Button
                    component={Link}
                    to="/settings/general"
                    variant="outlined"
                    className={classes.secondaryButton}
                    size="small"
                    aria-label="Go to Account Settings"
                  >
                    Account Settings
                  </Button>
                  <Button
                    component={Link}
                    to="/faq"
                    variant="outlined"
                    className={classes.secondaryButton}
                    size="small"
                    aria-label="Go to FAQ"
                  >
                    FAQ
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </Section>
  );
}

export default DashboardSection;
