// src/components/TargetJobInformation.jsx

import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  memo,
} from "react";
import { FormContext } from "./FormContext";
import {
  Paper,
  Snackbar,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { SubsectionHeader, FormField, DateHelperText } from "./FormComponents";
import WorkIcon from "@material-ui/icons/Work";
import BusinessIcon from "@material-ui/icons/Business";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import FormSectionSubHeader from "./FormSectionSubHeader";

import { doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { db } from "../util/firebase";

import { useAuth } from "../util/auth";
import { useRouter } from "../util/router";

import debounce from "lodash.debounce";
import isEqual from "lodash/isEqual";

import * as Yup from "yup";

const MAX_JOB_ENTRIES = 10;
const MAX_TOOLKIT_SELECTIONS = 20;

const useStyles = makeStyles((theme) => ({
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  formContent: {
    position: "relative",
  },
  formContainer: {
    paddingTop: theme.spacing(2),
  },
  entryContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  actionButtons: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
  checkboxGrid: {
    marginTop: theme.spacing(2),
  },
  checkboxLabel: {
    marginLeft: theme.spacing(1),
  },
  targetJobHeader: {
    fontWeight: 600,
    fontSize: "1.3rem",
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  autoSaveAlert: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  applicationDeadlineField: {
    "& .MuiInputBase-root": {},
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& input[type='date']::-webkit-calendar-picker-indicator": {
      color: theme.palette.primary.main,
    },
  },
}));

const generationOptions = [
  {
    name: "resume",
    label: "Resume",
    tooltip: "Generate a tailored resume for this job",
  },
  {
    name: "coverLetter",
    label: "Cover Letter",
    tooltip: "Create a customized cover letter",
  },
  {
    name: "references",
    label: "References",
    tooltip: "Prepare a list of professional references",
  },
  {
    name: "messageToRecruiter",
    label: "Message to Recruiter",
    tooltip: "Generate a personalized message for LinkedIn recruiters",
  },
  {
    name: "linkedinProfileOptimization",
    label: "LinkedIn Profile Optimization",
    tooltip: "Get suggestions to improve your LinkedIn profile",
  },
  {
    name: "interviewPreparation",
    label: "Interview Preparation",
    tooltip: "Prepare for job interviews with tailored questions and answers",
  },
  {
    name: "networkingEmailTemplates",
    label: "Networking Email Templates",
    tooltip: "Create templates for networking emails",
  },
  {
    name: "careerDevelopmentPlan",
    label: "Career Development Plan",
    tooltip: "Get a personalized career development plan",
  },
  {
    name: "jobApplicationFollowUpEmail",
    label: "Job Application Follow-up Emails",
    tooltip: "Generate follow-up emails for your job application",
  },
  {
    name: "salaryNegotiationScript",
    label: "Salary Negotiation Scripts",
    tooltip: "Receive scripts for salary negotiations",
  },
  {
    name: "professionalBio",
    label: "Professional Bio Creation",
    tooltip: "Create a professional bio for various platforms",
  },
  {
    name: "personalBrandingTips",
    label: "Personal Branding Tips",
    tooltip: "Get tips for building your personal brand",
  },
  {
    name: "skillGapAnalysis",
    label: "Skill Gap Analysis",
    tooltip: "Identify and address skill gaps for this job",
  },
  {
    name: "companyResearchReports",
    label: "Company Research Reports",
    tooltip: "Get detailed reports on the target company",
  },
];

const initialTargetJobInfo = {
  jobTitle: "",
  companyName: "",
  jobLocation: "",
  jobType: "",
  salary: "",
  applicationDeadline: "",
  jobDescription: "",
  generationOptions: generationOptions.reduce((acc, option) => {
    acc[option.name] = false;
    return acc;
  }, {}),
};

// Define formSections
const formSections = [
  {
    title: "Job Details",
    fields: [
      {
        name: "jobTitle",
        label: "Job Title",
        required: true,
        icon: WorkIcon,
        maxLength: 100,
      },
      {
        name: "companyName",
        label: "Company Name",
        required: true,
        icon: BusinessIcon,
        maxLength: 100,
      },
      {
        name: "jobLocation",
        label: "Job Location",
        maxLength: 100,
      },
      {
        name: "jobType",
        label: "Job Type",
        required: true,
        select: true,
        options: [
          { value: "Full-time", label: "Full-time" },
          { value: "Part-time", label: "Part-time" },
          { value: "Contract", label: "Contract" },
          { value: "Internship", label: "Internship" },
          { value: "Temporary", label: "Temporary" },
        ],
      },
    ],
  },
  {
    title: "Salary and Deadline",
    fields: [
      {
        name: "salary",
        label: "Salary",
        type: "text",
      },
      {
        name: "applicationDeadline",
        label: "Application Deadline",
        type: "date",
        helperText: "Enter the deadline for submitting your application",
        InputLabelProps: { shrink: true },
      },
    ],
  },
  {
    title: "Job Description",
    fields: [
      {
        name: "jobDescription",
        label: "Job Description",
        required: true,
        multiline: true,
        rows: 8,
        fullWidth: true,
        maxLength: 8000,
      },
    ],
  },
];

export const targetJobSchema = Yup.object().shape({
  jobTitle: Yup.string()
    .required("Job Title is required")
    .max(100, "Job Title cannot exceed 100 characters"),
  companyName: Yup.string()
    .required("Company Name is required")
    .max(100, "Company Name cannot exceed 100 characters"),
  jobLocation: Yup.string().max(
    100,
    "Job Location cannot exceed 100 characters"
  ),
  jobType: Yup.string()
    .required("Job Type is required")
    .oneOf(
      ["Full-time", "Part-time", "Contract", "Internship", "Temporary"],
      "Invalid Job Type"
    ),
  salary: Yup.string().max(100, "Salary cannot exceed 100 characters"),
  applicationDeadline: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(new Date(), "Application Deadline cannot be in the past"),
  jobDescription: Yup.string()
    .required("Job Description is required")
    .max(8000, "Job Description cannot exceed 8000 characters"),
  generationOptions: Yup.object().shape(
    generationOptions.reduce((acc, option) => {
      acc[option.name] = Yup.boolean();
      return acc;
    }, {})
  ),
});

export const formSchema = Yup.object().shape({
  targetJobInformation: Yup.array()
    .of(targetJobSchema)
    .min(1, "At least one target job must be added")
    .max(
      MAX_JOB_ENTRIES,
      `You can only add up to ${MAX_JOB_ENTRIES} target jobs`
    ),
});

// Update the normalizeJobData function
const normalizeJobData = (job) => {
  const jobGenerationOptions =
    typeof job.generationOptions === "object" && job.generationOptions !== null
      ? job.generationOptions
      : {};

  return {
    ...initialTargetJobInfo,
    ...job,
    generationOptions: {
      ...initialTargetJobInfo.generationOptions,
      ...jobGenerationOptions,
    },
  };
};

const TargetJobEntry = memo(
  ({
    index,
    jobInfo,
    handleFieldChange,
    handleOptionChange,
    handleRemoveEntry,
    handleAddEntry,
    errors,
    touched,
    countToolkitSelections,
    userCredits,
  }) => {
    const classes = useStyles();

    return (
      <Box className={classes.entryContainer}>
        <SubsectionHeader title={`Target Job ${index + 1}`} />
        {formSections.map((section) => (
          <React.Fragment key={section.title}>
            <SubsectionHeader title={section.title} />
            <Grid container spacing={3}>
              {section.fields.map((field) => (
                <Grid
                  item
                  xs={12}
                  sm={field.fullWidth ? 12 : 6}
                  key={field.name}
                >
                  <FormField
                    name={field.name}
                    label={field.label}
                    required={field.required}
                    value={jobInfo[field.name] ?? ""}
                    onChange={(name, value) =>
                      handleFieldChange(index, name, value)
                    }
                    error={
                      touched &&
                      touched[field.name] &&
                      errors &&
                      errors[field.name]
                    }
                    helperText={
                      touched &&
                      touched[field.name] &&
                      errors &&
                      errors[field.name]
                    }
                    tooltip={`Enter the ${field.label.toLowerCase()}`}
                    aria-label={field.label}
                    placeholder={
                      field.type === "date"
                        ? "mm/dd/yyyy"
                        : `Enter ${field.label.toLowerCase()}`
                    }
                    maxLength={field.maxLength}
                    type={field.type}
                    multiline={field.multiline}
                    rows={field.rows}
                    fullWidth
                    select={field.select}
                    options={field.options}
                    icon={field.icon ? <field.icon /> : null}
                    InputLabelProps={
                      field.type === "date" ? field.InputLabelProps : undefined
                    }
                    className={
                      field.name === "applicationDeadline"
                        ? classes.applicationDeadlineField
                        : undefined
                    }
                  >
                    {field.select &&
                      field.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </FormField>
                  {field.type === "date" && (
                    <DateHelperText text={field.helperText} />
                  )}
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
        <SubsectionHeader title="Job Application Toolkit:" />
        <Grid container spacing={2} className={classes.checkboxGrid}>
          {generationOptions.map((option) => {
            const isOptionSelected = Boolean(
              jobInfo.generationOptions[option.name]
            );
            return (
              <Grid item xs={12} sm={6} md={4} key={option.name}>
                <Tooltip title={option.tooltip} placement="top">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isOptionSelected}
                        onChange={(e) =>
                          handleOptionChange(
                            index,
                            option.name,
                            e.target.checked
                          )
                        }
                        name={option.name}
                        color="primary"
                        aria-label={option.label}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        className={classes.checkboxLabel}
                      >
                        {option.label}
                      </Typography>
                    }
                  />
                </Tooltip>
              </Grid>
            );
          })}
        </Grid>
        <Box className={classes.actionButtons}>
          <Tooltip title="Remove this target job">
            <IconButton
              onClick={() => handleRemoveEntry(index)}
              color="secondary"
              aria-label="Remove target job"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add another target job">
            <IconButton
              onClick={handleAddEntry}
              color="primary"
              aria-label="Add target job"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }
);

export const TargetJobInformation = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [autoSaveStatus, setAutoSaveStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [purchaseCreditsDialogOpen, setPurchaseCreditsDialogOpen] =
    useState(false);

  const auth = useAuth();
  const router = useRouter();

  const prevFormDataRef = useRef();

  const saveData = useCallback(
    async (data) => {
      localStorage.setItem("targetJobInformation", JSON.stringify(data));

      if (auth.user) {
        const userDocRef = doc(db, "users", auth.user.uid);
        try {
          await setDoc(
            userDocRef,
            { targetJobInformation: data },
            { merge: true }
          );
        } catch (error) {
          console.error("Error saving data:", error);
          throw error;
        }
      }
    },
    [auth.user]
  );

  const debouncedAutoSave = useCallback(
    debounce(async (data) => {
      try {
        setAutoSaveStatus("saving");
        await saveData(data);
        setAutoSaveStatus("success");
      } catch (error) {
        console.error("Auto-save failed:", error);
        setAutoSaveStatus("error");
      }
    }, 1000),
    [saveData]
  );

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const localData = localStorage.getItem("targetJobInformation");
        if (localData) {
          const parsedData = JSON.parse(localData);
          if (Array.isArray(parsedData)) {
            const normalizedData = parsedData.map((job) =>
              normalizeJobData(job)
            );
            updateFormData("targetJobInformation", normalizedData);
          } else {
            updateFormData("targetJobInformation", [
              { ...initialTargetJobInfo },
            ]);
          }
        } else if (auth.user) {
          const userDocRef = doc(db, "users", auth.user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const data = userDoc.data().targetJobInformation;
            if (data && Array.isArray(data)) {
              const normalizedData = data.map((job) => normalizeJobData(job));
              updateFormData("targetJobInformation", normalizedData);
              localStorage.setItem(
                "targetJobInformation",
                JSON.stringify(normalizedData)
              );
            }
          }
        } else {
          updateFormData("targetJobInformation", [{ ...initialTargetJobInfo }]);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [auth.user, updateFormData]);

  useEffect(() => {
    let unsubscribe;
    if (auth.user) {
      const userDocRef = doc(db, "users", auth.user.uid);
      unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setUserCredits(data.credits || 0);
        } else {
          setUserCredits(0);
        }
      });
    } else {
      setUserCredits(0);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [auth.user]);

  useEffect(() => {
    if (!loading) {
      const currentFormData = formData.targetJobInformation;
      const previousFormData = prevFormDataRef.current;

      if (previousFormData && !isEqual(currentFormData, previousFormData)) {
        debouncedAutoSave(currentFormData);
      }
      prevFormDataRef.current = currentFormData;
    }
  }, [formData.targetJobInformation, debouncedAutoSave, loading]);

  const countToolkitSelections = () => {
    const targetJobInformation = Array.isArray(formData.targetJobInformation)
      ? formData.targetJobInformation
      : [];
    return targetJobInformation.reduce((total, job) => {
      const generationOptions = job.generationOptions || {};
      return total + Object.values(generationOptions).filter(Boolean).length;
    }, 0);
  };

  const handleFieldChange = async (index, name, value) => {
    const updatedJob = {
      ...formData.targetJobInformation[index],
      [name]: value,
    };
    const updatedJobs = [...formData.targetJobInformation];
    updatedJobs[index] = updatedJob;
    updateFormData("targetJobInformation", updatedJobs);

    try {
      await targetJobSchema.validateAt(name, updatedJob);
      setErrors((prevErrors) => {
        const jobErrors = prevErrors[index] || {};
        delete jobErrors[name];
        return { ...prevErrors, [index]: jobErrors };
      });
      setTouched((prevTouched) => ({
        ...prevTouched,
        [index]: { ...(prevTouched[index] || {}), [name]: true },
      }));
    } catch (validationError) {
      setErrors((prevErrors) => {
        const jobErrors = prevErrors[index] || {};
        return {
          ...prevErrors,
          [index]: { ...jobErrors, [name]: validationError.message },
        };
      });
      setTouched((prevTouched) => ({
        ...prevTouched,
        [index]: { ...(prevTouched[index] || {}), [name]: true },
      }));
    }
  };

  const handleOptionChange = (index, optionName, checked) => {
    const totalSelections = countToolkitSelections();
    if (checked) {
      if (totalSelections >= MAX_TOOLKIT_SELECTIONS) {
        setAlertMessage(
          `You can only select up to ${MAX_TOOLKIT_SELECTIONS} toolkit options in total.`
        );
        return;
      }

      const estimatedCreditsRemaining = userCredits - (totalSelections + 1);

      if (estimatedCreditsRemaining < 0) {
        setPurchaseCreditsDialogOpen(true);
        return;
      }
    }

    const currentGenerationOptions =
      formData.targetJobInformation[index].generationOptions || {};
    const updatedJob = {
      ...formData.targetJobInformation[index],
      generationOptions: {
        ...currentGenerationOptions,
        [optionName]: checked,
      },
    };
    const updatedJobs = [...formData.targetJobInformation];
    updatedJobs[index] = updatedJob;
    updateFormData("targetJobInformation", updatedJobs);
  };

  const handleAddEntry = () => {
    if (formData.targetJobInformation.length >= MAX_JOB_ENTRIES) {
      setAlertMessage(`You can only add up to ${MAX_JOB_ENTRIES} target jobs.`);
      return;
    }
    updateFormData("targetJobInformation", [
      ...formData.targetJobInformation,
      { ...initialTargetJobInfo },
    ]);
  };

  const handleRemoveEntry = (index) => {
    // Ensure that we can never have less than 1 job entry
    if (formData.targetJobInformation.length === 1) {
      setAlertMessage("You must have at least one target job.");
      return;
    }

    const updatedJobs = formData.targetJobInformation.filter(
      (_, i) => i !== index
    );
    updateFormData("targetJobInformation", updatedJobs);

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[index];
      const reIndexedErrors = {};
      updatedJobs.forEach((_, i) => {
        reIndexedErrors[i] = newErrors[i >= index ? i + 1 : i];
      });
      return reIndexedErrors;
    });
    setTouched((prevTouched) => {
      const newTouched = { ...prevTouched };
      delete newTouched[index];
      const reIndexedTouched = {};
      updatedJobs.forEach((_, i) => {
        reIndexedTouched[i] = newTouched[i >= index ? i + 1 : i];
      });
      return reIndexedTouched;
    });
  };

  // Function to generate synthetic job entry
  const generateSyntheticJobEntry = () => {
    return {
      jobTitle: "Software Engineer",
      companyName: "TechCorp Solutions",
      jobLocation: "San Francisco, CA",
      jobType: "Full-time",
      salary: "$120,000 - $140,000",
      applicationDeadline: new Date().toISOString().split("T")[0],
      jobDescription: `We are seeking a highly skilled Software Engineer to join our dynamic development team. The ideal candidate will have extensive experience with JavaScript, React, and Node.js, and a strong understanding of software development best practices.`,
      generationOptions: { ...initialTargetJobInfo.generationOptions },
    };
  };

  const handleAddSyntheticEntry = () => {
    if (formData.targetJobInformation.length >= MAX_JOB_ENTRIES) {
      setAlertMessage(`You can only add up to ${MAX_JOB_ENTRIES} target jobs.`);
      return;
    }
    const syntheticJob = generateSyntheticJobEntry();
    updateFormData("targetJobInformation", [
      ...formData.targetJobInformation,
      syntheticJob,
    ]);
  };

  const targetJobInformation = Array.isArray(formData.targetJobInformation)
    ? formData.targetJobInformation
    : [{ ...initialTargetJobInfo }];

  const totalToolkitSelections = countToolkitSelections();
  const estimatedCreditsRemaining = userCredits - totalToolkitSelections;

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <FormSectionSubHeader title="Target Job Information:" />
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleAddSyntheticEntry}
      >
        Fill with Sample Data
      </Button> */}
      <div className={classes.formContent}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress aria-label="Loading target job information" />
          </Grid>
        ) : (
          <div className={classes.formContainer}>
            {targetJobInformation.map((jobInfo, index) => (
              <TargetJobEntry
                key={index}
                index={index}
                jobInfo={jobInfo}
                handleFieldChange={handleFieldChange}
                handleOptionChange={handleOptionChange}
                handleRemoveEntry={handleRemoveEntry}
                handleAddEntry={handleAddEntry}
                errors={errors[index] || {}}
                touched={touched[index] || {}}
                countToolkitSelections={countToolkitSelections}
                userCredits={userCredits}
              />
            ))}
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!alertMessage}
        autoHideDuration={6000}
        onClose={() => setAlertMessage(null)}
      >
        <Alert onClose={() => setAlertMessage(null)} severity="warning">
          {alertMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(autoSaveStatus)}
        autoHideDuration={2000}
        onClose={() => setAutoSaveStatus(null)}
      >
        <Alert
          onClose={() => setAutoSaveStatus(null)}
          severity={
            autoSaveStatus === "error"
              ? "error"
              : autoSaveStatus === "success"
              ? "success"
              : "info"
          }
          variant="filled"
          className={classes.autoSaveAlert}
        >
          {autoSaveStatus === "saving"
            ? "Auto-saving..."
            : autoSaveStatus === "success"
            ? "All changes saved"
            : "Auto-save failed"}
        </Alert>
      </Snackbar>
      <Dialog
        open={purchaseCreditsDialogOpen}
        onClose={() => setPurchaseCreditsDialogOpen(false)}
      >
        <DialogTitle>Not Enough Credits</DialogTitle>
        <DialogContent>
          <Typography>
            You don't have enough credits to select this option. Please purchase
            more credits to continue.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPurchaseCreditsDialogOpen(false)}
            color="default"
            variant="outlined"
          >
            Continue Editing
          </Button>
          <Button
            onClick={() => {
              setPurchaseCreditsDialogOpen(false);
              router.push("/pricing");
            }}
            color="primary"
            variant="contained"
          >
            Purchase Credits
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default TargetJobInformation;
