// src/components/useDashboardSectionStyles.js

import { makeStyles, lighten } from "@material-ui/core/styles";

const useDashboardSectionStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  paper: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: theme.spacing(3),
    gap: theme.spacing(2),
  },
  primaryButton: {
    padding: theme.spacing(1.5, 3),
    textTransform: "none",
    fontWeight: 600,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    maxWidth: 300,
    width: "100%",
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? lighten(theme.palette.primary.dark, 0.2)
          : lighten(theme.palette.primary.main, 0.1),
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  secondaryButton: {
    padding: theme.spacing(1, 3),
    textTransform: "none",
    borderRadius: theme.spacing(1),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
    color: theme.palette.text.primary,
    maxWidth: 300,
    width: "100%",
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? lighten(theme.palette.grey[800], 0.1)
          : lighten(theme.palette.grey[200], 0.1),
    },
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  linkButton: {
    padding: theme.spacing(1, 3),
    textTransform: "none",
    borderRadius: theme.spacing(1),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? lighten(theme.palette.grey[800], 0.1)
          : lighten(theme.palette.grey[200], 0.1),
    },
  },
  quickLinksContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: theme.spacing(3),
    gap: theme.spacing(2),
  },
  welcomeMessage: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(1),
    },
  },
  welcomeText: {
    fontWeight: 600,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  generationList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? lighten(theme.palette.grey[800], 0.1)
          : lighten(theme.palette.grey[100], 0.1),
    },
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? lighten(theme.palette.grey[800], 0.1)
          : lighten(theme.palette.grey[100], 0.1),
    },
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  searchFieldContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  searchField: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
    },
  },
  calendarIconButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? lighten(theme.palette.grey[800], 0.1)
          : lighten(theme.palette.grey[200], 0.1),
    },
  },
  sectionHeader: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  alertBox: {
    margin: "0 auto",
    marginBottom: theme.spacing(4),
    maxWidth: 500,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
  },
  cardContent: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.spacing(2),
  },
  aboutTypography: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.8,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.95rem",
    },
  },
  dashboardEssentials: {
    marginTop: theme.spacing(4),
  },
  dialogTitle: {
    borderBottom: `1px solid ${
      theme.palette.type === "dark"
        ? lighten(theme.palette.text.primary, 0.5)
        : lighten(theme.palette.text.primary, 0.9)
    }`,
    paddingBottom: theme.spacing(2),
  },
  deleteFooter: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  analyticsTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
    },
  },
  analyticsPaper: {
    padding: theme.spacing(3, 3, 3, 0),
    textAlign: "center",
    color: theme.palette.text.primary,
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[1],
    height: "100%", // Ensure full height
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  analyticsNumber: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
  },
  totalApplicationsText: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  LineChart: {
    marginLeft: theme.spacing(-4),
  },
}));

export default useDashboardSectionStyles;
