// src/utils/helpers.js

// Helper function to construct domain from company name
export const constructDomain = (companyName) => {
  if (!companyName) return "";
  const sanitized = companyName.toLowerCase().replace(/[^a-z0-9]/g, "");
  return sanitized ? `${sanitized}.com` : "";
};

// Function to get logo URL using Clearbit Logo API
export const getLogoUrl = (companyName) => {
  const domain = constructDomain(companyName);
  return domain ? `https://logo.clearbit.com/${domain}` : "";
};
