// src/components/GeneratedContentDisplay.jsx

import React, { useContext, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  CssBaseline,
  MenuItem,
  ListItemIcon,
  makeStyles,
  Snackbar,
  TextField,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Work as WorkIcon,
  Description as DescriptionIcon,
  Assignment as AssignmentIcon,
  Message as MessageIcon,
  LinkedIn as LinkedInIcon,
  School as SchoolIcon,
  Email as EmailIcon,
  AttachMoney as MoneyIcon,
  Person as PersonIcon,
  Star as StarIcon,
  Build as BuildIcon,
  Business as BusinessIcon,
} from "@material-ui/icons";

// Import application material components
import Resume from "./Resume";
import CoverLetter from "./CoverLetter";
import References from "./References";
import MessageToRecruiter from "./MessageToRecruiter";
import InterviewPreparation from "./InterviewPreparation";
import NetworkingEmailTemplates from "./NetworkingEmailTemplates";
import CareerDevelopmentPlan from "./CareerDevelopmentPlan";
import JobApplicationFollowUpEmail from "./JobApplicationFollowUpEmail";
import LinkedInProfileOptimization from "./LinkedInProfileOptimization";
import SalaryNegotiationScript from "./SalaryNegotiationScript";
import ProfessionalBio from "./ProfessionalBio";
import PersonalBrandingTips from "./PersonalBrandingTips";
import SkillGapAnalysis from "./SkillGapAnalysis";
import CompanyResearchReports from "./CompanyResearchReports";
import ApplicationMaterialSkeleton from "./ApplicationMaterialSkeleton";
import { FormContext } from "./FormContext";

// Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Define the content sections mapping, consolidating title and icon
const contentSections = {
  resume: {
    title: "Resume",
    icon: <WorkIcon />,
    component: Resume,
    getContent: (generatedContent) => generatedContent.resumeJson,
    getProps: (params) => ({
      content: params.generatedContent.resumeJson,
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent("resumeJson", updatedContent),
      handlePrintResume: params.handlePrintResume,
      downloading: params.downloading,
    }),
  },
  coverLetter: {
    title: "Cover Letter",
    icon: <DescriptionIcon />,
    component: CoverLetter,
    getContent: (generatedContent) => generatedContent.coverLetterJson,
    getProps: (params) => ({
      content: params.generatedContent.coverLetterJson,
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent("coverLetterJson", updatedContent),
      personalInformation: params.formData.personalInformation,
      onPersonalInfoChange: (field, value) =>
        params.updateFormData("personalInformation", {
          ...params.formData.personalInformation,
          [field]: value,
        }),
      handlePrintResume: params.handlePrintResume,
      downloading: params.downloading,
    }),
  },
  references: {
    title: "References",
    icon: <AssignmentIcon />,
    component: References,
    getContent: (generatedContent) => generatedContent.referencesJson,
    getProps: (params) => ({
      content: params.generatedContent.referencesJson,
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent("referencesJson", updatedContent),
    }),
  },
  messageToRecruiter: {
    title: "Message to Recruiter",
    icon: <MessageIcon />,
    component: MessageToRecruiter,
    getContent: (generatedContent) => generatedContent.messageToRecruiterJson,
    getProps: (params) => ({
      content: params.generatedContent.messageToRecruiterJson,
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent("messageToRecruiterJson", updatedContent),
      title: "Message to Recruiter",
    }),
  },
  linkedinProfileOptimization: {
    title: "LinkedIn Profile Optimization",
    icon: <LinkedInIcon />,
    component: LinkedInProfileOptimization,
    getContent: (generatedContent) =>
      generatedContent.linkedinProfileOptimizationJson,
    getProps: (params) => ({
      content: params.generatedContent.linkedinProfileOptimizationJson,
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent(
          "linkedinProfileOptimizationJson",
          updatedContent
        ),
    }),
  },
  interviewPreparation: {
    title: "Interview Preparation",
    icon: <SchoolIcon />,
    component: InterviewPreparation,
    getContent: (generatedContent) => generatedContent.interviewPreparationJson,
    getProps: (params) => ({
      content: params.generatedContent.interviewPreparationJson,
    }),
  },
  networkingEmailTemplates: {
    title: "Networking Email Templates",
    icon: <EmailIcon />,
    component: NetworkingEmailTemplates,
    getContent: (generatedContent) =>
      generatedContent.networkingEmailTemplatesJson,
    getProps: (params) => ({
      content: params.generatedContent.networkingEmailTemplatesJson,
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent(
          "networkingEmailTemplatesJson",
          updatedContent
        ),
    }),
  },
  careerDevelopmentPlan: {
    title: "Career Development Plan",
    icon: <AssignmentIcon />,
    component: CareerDevelopmentPlan,
    getContent: (generatedContent) =>
      generatedContent.careerDevelopmentPlanJson,
    getProps: (params) => ({
      content: params.generatedContent.careerDevelopmentPlanJson,
    }),
  },
  jobApplicationFollowUpEmail: {
    title: "Job Application Follow-Up Email",
    icon: <EmailIcon />,
    component: JobApplicationFollowUpEmail,
    getContent: (generatedContent) =>
      generatedContent.jobApplicationFollowUpEmailJson,
    getProps: (params) => ({
      content: params.generatedContent.jobApplicationFollowUpEmailJson,
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent(
          "jobApplicationFollowUpEmailJson",
          updatedContent
        ),
    }),
  },
  salaryNegotiationScript: {
    title: "Salary Negotiation Script",
    icon: <MoneyIcon />,
    component: SalaryNegotiationScript,
    getContent: (generatedContent) =>
      generatedContent.salaryNegotiationScriptJson,
    getProps: (params) => ({
      content: params.generatedContent.salaryNegotiationScriptJson,
    }),
  },
  professionalBio: {
    title: "Professional Bio",
    icon: <PersonIcon />,
    component: ProfessionalBio,
    getContent: (generatedContent) => generatedContent.professionalBioJson,
    getProps: (params) => ({
      content: params.generatedContent.professionalBioJson,
      onContentChange: (updatedContent) =>
        params.updateGeneratedContent("professionalBioJson", updatedContent),
    }),
  },
  personalBrandingTips: {
    title: "Personal Branding Tips",
    icon: <StarIcon />,
    component: PersonalBrandingTips,
    getContent: (generatedContent) => generatedContent.personalBrandingTipsJson,
    getProps: (params) => ({
      content: params.generatedContent.personalBrandingTipsJson,
    }),
  },
  skillGapAnalysis: {
    title: "Skill Gap Analysis",
    icon: <BuildIcon />,
    component: SkillGapAnalysis,
    getContent: (generatedContent) => generatedContent.skillGapAnalysisJson,
    getProps: (params) => ({
      content: params.generatedContent.skillGapAnalysisJson,
    }),
  },
  companyResearchReports: {
    title: "Company Research Reports",
    icon: <BusinessIcon />,
    component: CompanyResearchReports,
    getContent: (generatedContent) =>
      generatedContent.companyResearchReportsJson,
    getProps: (params) => ({
      content: params.generatedContent.companyResearchReportsJson,
    }),
  },
};

// Define styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    marginTop: -45,
    flexGrow: 1,
  },
  jobHeader: {
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    textAlign: "center", // Center the job title and company name
  },
  formControl: {
    minWidth: 250,
    maxWidth: 300, // Set a reasonable maximum width
    margin: "0 auto", // Center the dropdown
  },
  selectLabel: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  inputLabel: {
    color: theme.palette.text.primary,
  },
}));

const GeneratedContentDisplay = ({
  generatedContent,
  updateGeneratedContent,
  jobDetails = {},
  isLoading,
  selectedApplicationMaterial,
  onSelectApplicationMaterial,
}) => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [downloading, setDownloading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Memoize available sections to prevent unnecessary recalculations
  const availableSections = useMemo(
    () =>
      Object.keys(contentSections).filter((key) =>
        contentSections[key].getContent(generatedContent)
      ),
    [generatedContent]
  );

  // Initialize currentMaterial with selectedSection or empty string to prevent null
  const selectedSection =
    selectedApplicationMaterial ||
    (availableSections.length > 0 ? availableSections[0] : "");

  // State for current material (for Dropdown)
  const [currentMaterial, setCurrentMaterial] = useState(selectedSection || "");

  const handlePrintResume = async (
    content,
    templateName = "resume_template_10.docx",
    filename = "resume.docx",
    additionalData = {}
  ) => {
    setDownloading(true);
    try {
      const payload = {
        ...content,
        ...additionalData,
        templateName,
        outputFileName: filename,
      };

      const response = await fetch(
        "https://us-central1-resumerevivalapp.cloudfunctions.net/generate_resume_test",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate document");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      setSnackbar({
        open: true,
        message: "Document downloaded successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error downloading document:", error);
      setSnackbar({
        open: true,
        message: "Error downloading document.",
        severity: "error",
      });
    } finally {
      setDownloading(false);
    }
  };

  const selectedContentSection =
    selectedSection && contentSections[selectedSection];
  const SelectedComponent = selectedContentSection
    ? contentSections[selectedSection].component
    : null;
  const selectedContent = selectedContentSection
    ? contentSections[selectedSection].getContent(generatedContent)
    : null;

  const sectionProps = selectedContentSection
    ? contentSections[selectedSection].getProps({
        generatedContent,
        updateGeneratedContent,
        formData,
        updateFormData,
        handlePrintResume,
        downloading,
      })
    : {};

  // Update currentMaterial when selectedApplicationMaterial or availableSections change
  useEffect(() => {
    if (
      selectedApplicationMaterial &&
      availableSections.includes(selectedApplicationMaterial)
    ) {
      setCurrentMaterial(selectedApplicationMaterial);
    } else if (availableSections.length > 0) {
      setCurrentMaterial(availableSections[0]);
      if (onSelectApplicationMaterial) {
        onSelectApplicationMaterial(availableSections[0]);
      }
    } else {
      setCurrentMaterial("");
      if (onSelectApplicationMaterial) {
        onSelectApplicationMaterial("");
      }
    }
  }, [
    selectedApplicationMaterial,
    availableSections,
    onSelectApplicationMaterial,
  ]);

  const handleMaterialChange = (event) => {
    const newMaterial = event.target.value;
    setCurrentMaterial(newMaterial);
    if (onSelectApplicationMaterial) {
      onSelectApplicationMaterial(newMaterial);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <ApplicationMaterialSkeleton
          title={
            selectedContentSection
              ? contentSections[selectedSection].title
              : "Loading..."
          }
        />
      );
    }

    if (!selectedContentSection) {
      return (
        <Typography color="textSecondary" align="center">
          No content available for this job.
        </Typography>
      );
    }

    if (!selectedContent) {
      return (
        <Typography color="textSecondary" align="center">
          Select a section from the dropdown menu.
        </Typography>
      );
    }

    return <SelectedComponent {...sectionProps} />;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Typography variant="h5" className={classes.jobHeader}>
          {jobDetails.jobTitle && jobDetails.companyName
            ? `${jobDetails.jobTitle} at ${jobDetails.companyName}`
            : "Application Materials"}
        </Typography>

        {/* Material Dropdown */}
        {availableSections.length > 0 && (
          <TextField
            select
            label="Select Material"
            value={currentMaterial || ""}
            onChange={handleMaterialChange}
            variant="outlined"
            className={classes.formControl}
            SelectProps={{
              renderValue: (selected) => {
                if (selected && contentSections[selected]) {
                  return (
                    <Box className={classes.selectLabel}>
                      {contentSections[selected].icon}
                      <Typography variant="body1">
                        {contentSections[selected].title}
                      </Typography>
                    </Box>
                  );
                } else {
                  return (
                    <Typography variant="body1" color="textSecondary">
                      Select a material
                    </Typography>
                  );
                }
              },
              displayEmpty: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {availableSections.map((materialKey) => (
              <MenuItem
                key={materialKey}
                value={materialKey}
                className={classes.menuItem}
              >
                <ListItemIcon>{contentSections[materialKey].icon}</ListItemIcon>
                <Typography variant="body1">
                  {contentSections[materialKey].title}
                </Typography>
              </MenuItem>
            ))}
            {/* Optionally, handle the case where no material is selected */}
            {currentMaterial === "" && (
              <MenuItem value="" disabled>
                <Typography variant="body1" color="textSecondary">
                  Select a material
                </Typography>
              </MenuItem>
            )}
          </TextField>
        )}

        {/* Content Display */}
        <Box width="100%" mt={4}>
          {renderContent()}
        </Box>

        {/* Snackbar for success and error messages */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </main>
    </div>
  );
};

// Define PropTypes for the component
GeneratedContentDisplay.propTypes = {
  generatedContent: PropTypes.object.isRequired,
  updateGeneratedContent: PropTypes.func.isRequired,
  jobDetails: PropTypes.shape({
    jobTitle: PropTypes.string,
    companyName: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  selectedApplicationMaterial: PropTypes.string,
  onSelectApplicationMaterial: PropTypes.func,
};

export default GeneratedContentDisplay;
