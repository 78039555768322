// src/components/Meta.jsx

import React from "react";
import { Helmet } from "react-helmet";
import { useRouter } from "./../util/router.js";

function Meta(props) {
  const { children, ...customPageMeta } = props;
  const router = useRouter();

  const globalMeta = {
    siteName: "Resume Revival",
    domain: "https://www.resumerevival.xyz",
    twitterHandle: "@Resume_Revival",
    facebookPage: "https://www.facebook.com/profile.php?id=100091365447091",
    linkedinProfile: "https://www.linkedin.com/company/resumerevival-xyz/",
    instagramProfile: "https://www.instagram.com/resumerevival.xyz",
  };

  // Default meta values for current page (override with props)
  const defaultPageMeta = {
    // Page title
    title: "Resume Revival App",
    // Page description
    description:
      "Streamline your job hunt with Resume Revival – your free resume builder powered by AI. Tailor unique resumes and cover letters to match any job description. Perform skill gap analysis to boost your professional growth. ATS-optimized and keyword-rich applications. Free. Effective. User-friendly.",
    // Social share images
    image:
      "https://firebasestorage.googleapis.com/v0/b/resumerevival-bb193.appspot.com/o/1200x630.png?alt=media&token=6359271a-c328-4ee1-ac3c-525f2a39b84b", // Default image
    imageFacebook:
      "https://firebasestorage.googleapis.com/v0/b/resumerevival-bb193.appspot.com/o/1200x630.png?alt=media&token=6359271a-c328-4ee1-ac3c-525f2a39b84b", // Replace with Facebook-optimized image URL
    imageTwitter:
      "https://firebasestorage.googleapis.com/v0/b/resumerevival-bb193.appspot.com/o/1200x628.png?alt=media&token=4cfe041b-f204-4ac0-8b32-0cb8457016af", // Replace with Twitter-optimized image URL
    // Page type
    type: "website",
    // Keywords for SEO
    keywords:
      "AI job applications, resume builder, cover letter generator, job search acceleration",
  };

  // Merge global, default, and custom meta
  const meta = { ...globalMeta, ...defaultPageMeta, ...customPageMeta };

  // Construct full URL
  const currentUrl = `${meta.domain}${router.pathname}${
    router.location?.search || ""
  }`;

  // Use platform-specific images or default to 'image'
  const imageUrl = meta.image.startsWith("http")
    ? meta.image
    : `${meta.domain}${meta.image}`;

  const imageUrlFacebook = meta.imageFacebook
    ? meta.imageFacebook.startsWith("http")
      ? meta.imageFacebook
      : `${meta.domain}${meta.imageFacebook}`
    : imageUrl; // Fallback to default image

  const imageUrlTwitter = meta.imageTwitter
    ? meta.imageTwitter.startsWith("http")
      ? meta.imageTwitter
      : `${meta.domain}${meta.imageTwitter}`
    : imageUrl; // Fallback to default image

  // JSON-LD Structured Data for Organization
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: meta.domain,
    name: meta.siteName,
    logo: `${meta.domain}/logo.png`,
    sameAs: [
      meta.facebookPage,
      meta.instagramProfile,
      meta.linkedinProfile,
      `https://twitter.com/${meta.twitterHandle.replace("@", "")}`,
    ],
    description: meta.description,
    // Optional: Include contact information
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+1-123-456-7890", // Replace with actual contact number
        contactType: "customer service",
        areaServed: "US",
        availableLanguage: ["English"],
      },
    ],
  };

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} key="description" />
      {meta.keywords && (
        <meta name="keywords" content={meta.keywords} key="keywords" />
      )}
      <link rel="canonical" href={currentUrl} key="canonical" />
      <meta name="ir-site-verification-token" content="759085616" />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content={meta.title} key="og-title" />
      <meta
        property="og:description"
        content={meta.description}
        key="og-description"
      />
      <meta
        property="og:site_name"
        content={meta.siteName}
        key="og-site-name"
      />
      <meta property="og:type" content={meta.type} key="og-type" />
      <meta property="og:url" content={currentUrl} key="og-url" />
      <meta property="og:image" content={imageUrlFacebook} key="og-image" />
      <meta property="og:image:alt" content={meta.title} key="og-image-alt" />
      <meta property="og:image:type" content="image/png" key="og-image-type" />
      <meta property="og:image:width" content="1200" key="og-image-width" />
      <meta property="og:image:height" content="630" key="og-image-height" />

      {/* Twitter */}
      <meta name="twitter:title" content={meta.title} key="twitter-title" />
      <meta
        name="twitter:description"
        content={meta.description}
        key="twitter-description"
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter-card"
      />
      <meta
        name="twitter:site"
        content={meta.twitterHandle}
        key="twitter-site"
      />
      <meta
        name="twitter:image"
        content={imageUrlTwitter}
        key="twitter-image"
      />
      <meta
        name="twitter:image:alt"
        content={meta.title}
        key="twitter-image-alt"
      />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" key="robots" />
      <meta name="theme-color" content="#ffffff" key="theme-color" />

      {/* Favicon */}
      <link rel="icon" href="/favicon.ico" />

      {/* JSON-LD Structured Data */}
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>

      {/* Add more meta tags as needed */}
      {children}
    </Helmet>
  );
}

export default Meta;
