// src/components/AnalyticsSection.js

import React, { useMemo, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip as MuiTooltip,
  IconButton,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import { Info as InfoIcon } from "@material-ui/icons";
import useDashboardSectionStyles from "./useDashboardSectionStyles";

function parseTimestamp(timestamp) {
  if (timestamp instanceof Date) {
    return timestamp;
  } else if (timestamp && typeof timestamp.toDate === "function") {
    return timestamp.toDate();
  } else if (typeof timestamp === "string") {
    let str = timestamp.replace(" at ", " ");
    str = str.replace(/\u202F/g, " ");
    str = str.replace(/UTC[\+\-]\d+/, "");
    return new Date(str);
  } else {
    return new Date();
  }
}

// Updated colors array to include Rejected (red)
const progressColors = ["#3f51b5", "#ff9800", "#4caf50", "#2e7d32", "#f44336"];

function AnalyticsSection({ generations }) {
  const classes = useDashboardSectionStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [daysRange, setDaysRange] = useState(7);

  const handleDaysChange = useCallback((e) => {
    setDaysRange(e.target.value);
  }, []);

  const analytics = useMemo(() => {
    const totalGenerations = generations.length;
    if (totalGenerations === 0) {
      return null;
    }

    const dateCounts = {};
    for (let i = daysRange - 1; i >= 0; i--) {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() - i);
      const dateString = date.toISOString().split("T")[0];
      dateCounts[dateString] = 0;
    }

    let closedCount = 0;
    let activeCount = 0;
    // Now we have 5 entries: Applied, Interview, Offer, Accepted, Rejected
    const progressDistribution = [0, 0, 0, 0, 0];

    generations.forEach((gen) => {
      const dateObj = parseTimestamp(
        gen.timestamp || gen.createdAt || gen.start || gen.end || Date.now()
      );
      const dateString = dateObj.toISOString().split("T")[0];
      if (dateCounts[dateString] !== undefined) {
        dateCounts[dateString] += 1;
      }

      if (gen.status === "closed") {
        // Closed now represents a rejected application
        closedCount += 1;
        progressDistribution[4] += 1; // Rejected
      } else {
        activeCount += 1;
        const p = gen.progress !== undefined ? gen.progress : 0;
        // Only increment the first four indexes if it's not closed
        if (p >= 0 && p < 4) {
          progressDistribution[p] += 1;
        }
      }
    });

    const dateData = Object.keys(dateCounts).map((date) => ({
      date,
      count: dateCounts[date],
    }));

    const totalInRange = dateData.reduce((acc, curr) => acc + curr.count, 0);
    const avgPerDay = totalInRange / daysRange;

    // Updated to include "Rejected"
    const progressData = [
      {
        name: "Applied",
        value: progressDistribution[0],
        fill: progressColors[0],
      },
      {
        name: "Interview",
        value: progressDistribution[1],
        fill: progressColors[1],
      },
      {
        name: "Offer",
        value: progressDistribution[2],
        fill: progressColors[2],
      },
      {
        name: "Accepted",
        value: progressDistribution[3],
        fill: progressColors[3],
      },
      {
        name: "Rejected",
        value: progressDistribution[4],
        fill: progressColors[4],
      },
    ];

    return {
      totalGenerations,
      dateData,
      totalInRange,
      avgPerDay,
      progressData,
      activeCount,
      closedCount,
      progressDistribution,
    };
  }, [generations, daysRange]);

  const formatDateLabel = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}`;
  };

  const formatTooltipLabel = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };

  if (!analytics) {
    return (
      <Box mt={5} textAlign="center">
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          Your Analytics
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ marginTop: theme.spacing(2) }}
        >
          No data available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt={5}>
      {/* Title and Days Selector */}
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        alignItems={isMobile ? "flex-start" : "center"}
        justifyContent="center"
        mb={2}
        position="relative"
      >
        <Typography
          variant="h6"
          gutterBottom
          className={classes.analyticsTitle}
          style={{
            fontWeight: 600,
            textAlign: "center",
            width: isMobile ? "100%" : "auto",
            marginBottom: isMobile ? theme.spacing(1) : 0,
          }}
        >
          Your Analytics
        </Typography>
        <Box
          ml={isMobile ? 0 : "auto"}
          mt={isMobile ? theme.spacing(1) : 0}
          display="flex"
          justifyContent="center"
          width={isMobile ? "100%" : "auto"}
        >
          <FormControl variant="outlined" size="small">
            <InputLabel id="days-range-label">Days</InputLabel>
            <Select
              labelId="days-range-label"
              value={daysRange}
              onChange={handleDaysChange}
              label="Days"
              style={{ minWidth: 120 }}
            >
              <MenuItem value={7}>Last 7 Days</MenuItem>
              <MenuItem value={14}>Last 14 Days</MenuItem>
              <MenuItem value={30}>Last 30 Days</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Line Chart: Applications Over Selected Period */}
      <Box mb={3}>
        <Paper className={classes.analyticsPaper} style={{ overflowX: "auto" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            mb={1}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{
                textAlign: "center",
                width: "100%",
                fontWeight: 600,
              }}
            >
              Applications Over Last {daysRange} Days
            </Typography>
            <Box
              position="absolute"
              right={theme.spacing(2)}
              top="50%"
              style={{ transform: "translateY(-50%)" }}
            >
              <MuiTooltip
                title="This chart shows how many applications you generated each day in the selected period."
                arrow
              >
                <IconButton size="small" aria-label="Info">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </MuiTooltip>
            </Box>
          </Box>
          {analytics.totalInRange === 0 ? (
            <Typography
              variant="body1"
              style={{ textAlign: "center", margin: theme.spacing(2) }}
            >
              No applications found in this period.
            </Typography>
          ) : (
            <>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={analytics.dateData}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    tickFormatter={formatDateLabel}
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis allowDecimals={false} tick={{ fontSize: 12 }} />
                  <Tooltip
                    labelFormatter={formatTooltipLabel}
                    contentStyle={{
                      backgroundColor: theme.palette.background.paper,
                      borderColor: theme.palette.divider,
                    }}
                    labelStyle={{ color: theme.palette.text.primary }}
                    itemStyle={{ color: theme.palette.text.secondary }}
                  />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke={theme.palette.primary.main}
                    strokeWidth={2}
                    dot={{ r: 3 }}
                    activeDot={{ r: 6 }}
                    animationDuration={800}
                  />
                </LineChart>
              </ResponsiveContainer>
              <Typography
                variant="body1"
                className={classes.totalApplicationsText}
                style={{ textAlign: "center", marginTop: theme.spacing(2) }}
              >
                {analytics.totalInRange} applications generated in this period,
                averaging {analytics.avgPerDay.toFixed(2)} per day.
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ textAlign: "center", marginTop: theme.spacing(1) }}
              >
                {analytics.totalGenerations} total applications generated
                overall.
              </Typography>
            </>
          )}
        </Paper>
      </Box>

      {/* Progress Distribution */}
      <Box mb={3}>
        <Paper className={classes.analyticsPaper}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            mb={1}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ textAlign: "center", width: "100%", fontWeight: 600 }}
            >
              Progress Distribution
            </Typography>
            <Box
              position="absolute"
              right={theme.spacing(2)}
              top="50%"
              style={{ transform: "translateY(-50%)" }}
            >
              <MuiTooltip
                title="This pie chart shows the distribution of your applications across different stages."
                arrow
              >
                <IconButton size="small" aria-label="Info">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </MuiTooltip>
            </Box>
          </Box>
          {analytics.progressData.every((d) => d.value === 0) ? (
            <Typography
              variant="body1"
              style={{ textAlign: "center", margin: theme.spacing(2) }}
            >
              No applications in any stage.
            </Typography>
          ) : (
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie
                  data={analytics.progressData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={80}
                  label
                  animationDuration={800}
                >
                  {analytics.progressData.map((entry, index) => (
                    <Cell key={`cell-progress-${index}`} fill={entry.fill} />
                  ))}
                </Pie>
                <Legend verticalAlign="bottom" height={36} />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          )}
        </Paper>
      </Box>
    </Box>
  );
}

export default AnalyticsSection;
