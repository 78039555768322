// src/components/PromoBanner.jsx
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: "#4D4FB2",
    color: "#FFDAB9", // Warm peach color for text
    padding: theme.spacing(2),
    textAlign: "center",
    borderRadius: "8px",
    border: "2px solid #FFDAB9", // Border to match warm glow theme
    boxShadow: "0 0 10px #FFDAB9", // Soft glow around the banner
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  bannerText: {
    textShadow: "0 0 8px rgba(255, 215, 185, 1)", // Warm glow effect for text
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
}));

function PromoBanner({ message }) {
  const classes = useStyles();

  return (
    <Box className={classes.banner}>
      <Typography variant="h6" className={classes.bannerText}>
        {message}
      </Typography>
    </Box>
  );
}

export default PromoBanner;
