// src/components/AboutSection.js

import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Link as MuiLink,
} from "@material-ui/core";
import { EmojiObjects, HelpOutline } from "@material-ui/icons";
import { Link } from "../util/router";
import useDashboardSectionStyles from "./useDashboardSectionStyles";

function AboutSection({ auth }) {
  const classes = useDashboardSectionStyles();

  return (
    <Card className={classes.card} elevation={0}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.aboutSection}>
          <Typography variant="h6" paragraph>
            <EmojiObjects className={classes.icon} />
            <strong>About Resume Revival</strong>
          </Typography>
          <Typography paragraph className={classes.aboutTypography}>
            Resume Revival is your personal career assistant, helping you create
            professional, ATS-optimized resumes and cover letters tailored to
            your dream job. Our platform leverages advanced AI to highlight your
            strengths and match your skills with job requirements.
          </Typography>
          <Typography paragraph className={classes.aboutTypography}>
            Whether you're starting fresh or looking to improve your existing
            resume, we're here to support you every step of the way.
          </Typography>
          <Typography variant="h6" paragraph>
            <HelpOutline className={classes.icon} />
            <strong>Need Help?</strong>
          </Typography>
          <div>
            <Button
              component={Link}
              to="/contact"
              variant="contained"
              color="primary"
              size="small"
              className={classes.linkButton}
              aria-label="Go to Help Center"
            >
              Help Center
            </Button>
            <Button
              component={Link}
              to="/contact"
              variant="contained"
              color="primary"
              size="small"
              className={classes.linkButton}
              aria-label="Contact Us"
            >
              Contact Us
            </Button>
          </div>

          {/* Dashboard Essentials */}
          <Box className={classes.dashboardEssentials}>
            <Typography variant="h6" paragraph>
              <strong>Dashboard Essentials</strong>
            </Typography>
            <Typography component="div">
              <div>
                You are signed in as <strong>{auth.user.email}</strong>.
              </div>

              <div>
                You can change your account info
                {auth.user.stripeSubscriptionId && " and plan"} in{" "}
                <MuiLink component={Link} to="/settings/general">
                  <strong>settings</strong>
                </MuiLink>
                .
              </div>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default AboutSection;
