// src/pages/PricingPage.jsx
import React, { useState } from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
import PromoBanner from "./../components/PromoBanner";
import CountdownTimer from "./../components/CountdownTimer";
import {
  Container,
  Typography,
  Link as MuiLink,
  Dialog,
  DialogContent,
  IconButton,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { promoConfig } from "./../util/promoConfig";

const useStyles = makeStyles((theme) => ({
  promoStickyContainer: {
    position: "fixed",
    top: 90,
    padding: theme.spacing(2),
    width: "100%",
    zIndex: 1300,
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
    [theme.breakpoints.down("xs")]: {
      top: 70,
    },
  },
  topWrapper: {
    paddingTop: theme.spacing(22),
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(22),
    },
  },
  topWrapperNoPromo: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: "center",
  },
  videoLink: {
    display: "inline-block",
    marginTop: theme.spacing(2),
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  dialogClose: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 1400,
  },
  // Always vertical (9:16) aspect ratio
  videoWrapper: {
    position: "relative",
    paddingBottom: "177.77%", // 9:16 aspect ratio for the vertical video
    height: 0,
    overflow: "hidden",
    "& iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  },
  dialogPaper: {
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      width: "100%",
      height: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
}));

function PricingPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [videoOpen, setVideoOpen] = useState(false);

  const handleVideoOpen = () => {
    setVideoOpen(true);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  // Check for active promo
  const now = new Date();
  const start = new Date(promoConfig.startDate);
  const end = new Date(promoConfig.endDate);
  const isPromoActive = now >= start && now <= end;

  return (
    <>
      <Meta title="Pricing" />
      {isPromoActive && (
        <Box className={classes.promoStickyContainer}>
          <PromoBanner message={promoConfig.message} />
        </Box>
      )}
      <Container
        maxWidth="md"
        className={
          isPromoActive ? classes.topWrapper : classes.topWrapperNoPromo
        }
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Upgrade Your Job Search
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Choose the plan that fits your needs and accelerate your career.
        </Typography>

        <MuiLink onClick={handleVideoOpen} className={classes.videoLink}>
          Watch Explainer Video
        </MuiLink>
      </Container>

      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
        isPromoActive={isPromoActive}
        promoDiscountPercent={promoConfig.discountPercent}
        promoCode={promoConfig.promoCode}
      />

      {isPromoActive && <CountdownTimer endDate={promoConfig.endDate} />}

      <Dialog
        open={videoOpen}
        onClose={handleVideoClose}
        fullScreen={isMobile}
        // Keep the dialog narrower on desktop to avoid it looking awkward
        maxWidth="xs"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="explainer-video-dialog"
      >
        <IconButton
          aria-label="close"
          className={classes.dialogClose}
          onClick={handleVideoClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box className={classes.videoWrapper}>
            <iframe
              src="https://www.youtube.com/embed/02ijEui1dFk"
              title="Explainer Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PricingPage;
