// src/components/CountdownTimer.jsx
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

// Helper to pad numbers
function pad(num) {
  if (!Number.isFinite(num)) {
    num = 0;
  }
  return String(num).padStart(2, "0");
}

// Calculate remaining time
function getTimeRemaining(endDate) {
  const total = Date.parse(endDate) - Date.parse(new Date());
  let days = Math.floor(total / (1000 * 60 * 60 * 24));
  let hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  let minutes = Math.floor((total / (1000 * 60)) % 60);
  let seconds = Math.floor((total / 1000) % 60);

  if (!Number.isFinite(days) || days < 0) days = 0;
  if (!Number.isFinite(hours) || hours < 0) hours = 0;
  if (!Number.isFinite(minutes) || minutes < 0) minutes = 0;
  if (!Number.isFinite(seconds) || seconds < 0) seconds = 0;

  return { total, days, hours, minutes, seconds };
}

const useStyles = makeStyles((theme) => ({
  "@keyframes pulse": {
    "0%": { boxShadow: "0 0 10px #FFDAB9" },
    "50%": { boxShadow: "0 0 20px #FFDAB9" },
    "100%": { boxShadow: "0 0 10px #FFDAB9" },
  },
  timerContainer: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    backgroundColor: "#4D4FB2",
    color: "#FFFFFF",
    padding: theme.spacing(2, 3),
    borderRadius: "8px",
    boxShadow: theme.shadows[3],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 9999,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.5, 2),
      left: "50%",
      transform: "translateX(-50%)",
      bottom: theme.spacing(1),
    },
    transition: "transform 0.3s, opacity 0.3s",
    animation: "$pulse 2.5s infinite ease-in-out",
    maxWidth: "95vw", // Ensure it doesn't exceed viewport width
    boxSizing: "border-box",
  },
  collapsed: {
    transform: "translateX(-150%)",
    opacity: 0,
  },
  titleLine: {
    fontWeight: "bold",
    fontSize: "1.3rem",
    textShadow: "0 0 8px rgba(255, 215, 185, 1)",
    textAlign: "center",
    margin: 0,
    color: "#FFDAB9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  hurryLine: {
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(0.3),
    },
  },
  offerLine: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  flipClock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "nowrap",
    },
  },
  segment: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 0.5),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0.3),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 0.2),
    },
  },
  label: {
    fontSize: "0.7rem",
    textTransform: "uppercase",
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.65rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
      marginTop: theme.spacing(0.3),
    },
    color: "#FFDAB9",
    textShadow: "0 0 5px #FFDAB9",
  },
  digitWrapper: {
    position: "relative",
    width: "2.5em",
    height: "3em",
    perspective: "1000px",
    margin: theme.spacing(0, 0.5),
    [theme.breakpoints.down("sm")]: {
      width: "2.3em",
      height: "2.8em",
      margin: theme.spacing(0, 0.4),
    },
    [theme.breakpoints.down("xs")]: {
      width: "2em",
      height: "2.4em",
      margin: theme.spacing(0, 0.3),
    },
  },
  digit: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#222222",
    color: "#FFDAB9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2rem",
    fontWeight: "bold",
    borderRadius: "4px",
    textShadow: "0 0 5px #FFDAB9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
    },
    backfaceVisibility: "hidden",
  },
  flipCard: {
    transformStyle: "preserve-3d",
    transition: "transform 0.7s ease-in-out",
  },
  flipBack: {
    transform: "rotateX(180deg)",
  },
  divider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2rem",
    color: "#FFDAB9",
    margin: theme.spacing(0, 1),
    textShadow: "0 0 5px #FFDAB9",
    position: "relative",
    top: "-12px", // Adjust this value until the colons visually align with the digits
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
      margin: theme.spacing(0, 0.8),
      top: "-10px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
      margin: theme.spacing(0, 0.6),
      top: "-8px",
    },
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      top: theme.spacing(0.3),
      right: theme.spacing(0.3),
      padding: theme.spacing(0.4),
    },
  },
}));

function FlipDigit({ value }) {
  const classes = useStyles();
  const [currentVal, setCurrentVal] = useState(value);
  const [prevVal, setPrevVal] = useState(value);
  const [flip, setFlip] = useState(false);

  useEffect(() => {
    if (value !== currentVal) {
      setPrevVal(currentVal);
      setCurrentVal(value);
      setFlip(true);
      const timer = setTimeout(() => {
        setFlip(false);
      }, 700);
      return () => clearTimeout(timer);
    }
  }, [value, currentVal]);

  return (
    <div className={classes.digitWrapper}>
      <div
        className={clsx(classes.digit, classes.flipCard)}
        style={{ transform: flip ? "rotateX(-180deg)" : "rotateX(0)" }}
      >
        {currentVal}
      </div>
      <div
        className={clsx(classes.digit, classes.flipCard, classes.flipBack)}
        style={{ transform: flip ? "rotateX(0deg)" : "rotateX(180deg)" }}
      >
        {prevVal}
      </div>
    </div>
  );
}

function CountdownTimer({ endDate }) {
  const classes = useStyles();
  const [remaining, setRemaining] = useState(getTimeRemaining(endDate));
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = getTimeRemaining(endDate);
      setRemaining(timeLeft);
    }, 1000);
    return () => clearInterval(interval);
  }, [endDate]);

  if (remaining.total <= 0) {
    return null;
  }

  const days = pad(remaining.days);
  const hours = pad(remaining.hours);
  const minutes = pad(remaining.minutes);
  const seconds = pad(remaining.seconds);

  return (
    <Paper
      className={clsx(classes.timerContainer, collapsed && classes.collapsed)}
      aria-label="Promo Countdown Timer"
      role="timer"
      aria-live="polite"
    >
      <IconButton
        className={classes.closeButton}
        size="small"
        onClick={() => setCollapsed(!collapsed)}
        aria-label={collapsed ? "Expand timer" : "Collapse timer"}
      >
        <CloseIcon />
      </IconButton>
      <Typography className={clsx(classes.titleLine, classes.hurryLine)}>
        Hurry! Limited time only
      </Typography>
      <Typography className={clsx(classes.titleLine, classes.offerLine)}>
        Offer Ends In:
      </Typography>
      <div className={classes.flipClock}>
        <div className={classes.segment}>
          <div style={{ display: "flex" }}>
            <FlipDigit value={days[0]} />
            <FlipDigit value={days[1]} />
          </div>
          <Typography className={classes.label}>Days</Typography>
        </div>

        <span className={classes.divider}>:</span>

        <div className={classes.segment}>
          <div style={{ display: "flex" }}>
            <FlipDigit value={hours[0]} />
            <FlipDigit value={hours[1]} />
          </div>
          <Typography className={classes.label}>Hours</Typography>
        </div>

        <span className={classes.divider}>:</span>

        <div className={classes.segment}>
          <div style={{ display: "flex" }}>
            <FlipDigit value={minutes[0]} />
            <FlipDigit value={minutes[1]} />
          </div>
          <Typography className={classes.label}>Minutes</Typography>
        </div>

        <span className={classes.divider}>:</span>

        <div className={classes.segment}>
          <div style={{ display: "flex" }}>
            <FlipDigit value={seconds[0]} />
            <FlipDigit value={seconds[1]} />
          </div>
          <Typography className={classes.label}>Seconds</Typography>
        </div>
      </div>
    </Paper>
  );
}

export default CountdownTimer;
