import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Divider,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Button,
  Switch,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import {
  Person as PersonIcon,
  Work as WorkIcon,
  School as SchoolIcon,
  Star as StarIcon,
} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import DownloadButton from "./DownloadButton"; // Importing the DownloadButton component

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.text.primary,
  },
  editableField: { marginBottom: theme.spacing(2) },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  bulletList: {
    margin: 0,
    paddingLeft: theme.spacing(4),
  },
  bulletItem: {
    display: "list-item",
    listStyleType: "disc",
    listStylePosition: "outside",
    paddingLeft: 0,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  fieldLabel: { fontWeight: "bold", marginBottom: theme.spacing(0.5) },
  arrayItemHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  previewText: {
    marginBottom: theme.spacing(1),
    whiteSpace: "pre-wrap",
  },
}));

const updateNestedObject = (obj, pathArray, value) => {
  if (pathArray.length === 0) return value;
  if (pathArray.length === 1) {
    if (Array.isArray(obj)) {
      const index = parseInt(pathArray[0], 10);
      const newArr = obj.slice();
      newArr[index] = value;
      return newArr;
    } else {
      return {
        ...obj,
        [pathArray[0]]: value,
      };
    }
  }

  const [firstKey, ...restKeys] = pathArray;

  if (Array.isArray(obj)) {
    const index = parseInt(firstKey, 10);
    const newArr = obj.slice();

    const nextObj = obj[index] !== undefined ? obj[index] : {};
    newArr[index] = updateNestedObject(nextObj, restKeys, value);
    return newArr;
  } else {
    return {
      ...obj,
      [firstKey]: updateNestedObject(obj[firstKey] || {}, restKeys, value),
    };
  }
};

const Resume = ({
  content,
  onContentChange,
  handlePrintResume,
  downloading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [previewMode, setPreviewMode] = useState(false);

  if (!content || typeof content !== "object")
    return <Typography>Invalid resume data</Typography>;

  const handleFieldChange = (path, value) => {
    const updatedContent = updateNestedObject(content, path, value);
    onContentChange(updatedContent);
  };

  const handleAddArrayItem = (path, newItem) => {
    const currentArray = path.reduce((acc, key) => acc[key], content) || [];
    const updatedArray = [...currentArray, newItem];
    handleFieldChange(path, updatedArray);
  };

  const handleRemoveArrayItem = (path, indexToRemove) => {
    const currentArray = path.reduce((acc, key) => acc[key], content) || [];
    const updatedArray = currentArray.filter((_, i) => i !== indexToRemove);
    handleFieldChange(path, updatedArray);
  };

  const renderAdditionalQualification = (qualification, qualificationIndex) => {
    const { qualificationType, details } = qualification;
    if (previewMode) {
      // Preview mode display
      switch (qualificationType) {
        case "Military Service":
          const displayBranch = details.branch?.trim();
          const displayRank = details.rank?.trim();
          const displayStart = details.startDate
            ? `Start Date: ${details.startDate}`
            : "";
          const displayEnd = details.endDate
            ? `End Date: ${details.endDate}`
            : "";
          const displayTraining = details.specialTraining?.trim();

          return (
            <Box marginTop={2}>
              {displayBranch && (
                <Typography className={classes.previewText}>
                  <strong>Branch:</strong> {displayBranch}
                </Typography>
              )}
              {displayRank && (
                <Typography className={classes.previewText}>
                  <strong>Rank:</strong> {displayRank}
                </Typography>
              )}
              {(displayStart || displayEnd) && (
                <Typography className={classes.previewText}>
                  <strong>Service Period:</strong>{" "}
                  {[displayStart, displayEnd].filter(Boolean).join(" - ")}
                </Typography>
              )}
              {displayTraining && (
                <Typography className={classes.previewText}>
                  <strong>Special Training/Skills:</strong> {displayTraining}
                </Typography>
              )}
            </Box>
          );
        case "Certification":
          const displayCredentialType = details.credentialType?.trim();
          const displayName = details.name?.trim();
          const displayIssuer = details.issuer?.trim();
          const displayDateObtained = details.dateObtained
            ? `Date Obtained: ${details.dateObtained}`
            : "";
          const displayExpiration = details.expirationDate
            ? `Expiration: ${details.expirationDate}`
            : "";
          const displayDescription = details.description?.trim();

          return (
            <Box marginTop={2}>
              {displayCredentialType && (
                <Typography className={classes.previewText}>
                  <strong>Type:</strong> {displayCredentialType}
                </Typography>
              )}
              {displayName && (
                <Typography className={classes.previewText}>
                  <strong>Name:</strong> {displayName}
                </Typography>
              )}
              {displayIssuer && (
                <Typography className={classes.previewText}>
                  <strong>Issuer:</strong> {displayIssuer}
                </Typography>
              )}
              {(displayDateObtained || displayExpiration) && (
                <Typography className={classes.previewText}>
                  <strong>Validity:</strong>{" "}
                  {[displayDateObtained, displayExpiration]
                    .filter(Boolean)
                    .join(" | ")}
                </Typography>
              )}
              {displayDescription && (
                <Typography className={classes.previewText}>
                  <strong>Description:</strong> {displayDescription}
                </Typography>
              )}
            </Box>
          );
        case "Personal Project":
          const projectName = details.projectName?.trim();
          const projectDesc = details.description?.trim();
          const projectSkills = (details.skills || [])
            .filter((s) => s)
            .join(", ");
          const projectOutcome = details.outcome?.trim();
          const projectUrl = details.projectUrl?.trim();

          return (
            <Box marginTop={2}>
              {projectName && (
                <Typography className={classes.previewText}>
                  <strong>Project Name:</strong> {projectName}
                </Typography>
              )}
              {projectDesc && (
                <Typography className={classes.previewText}>
                  <strong>Description:</strong> {projectDesc}
                </Typography>
              )}
              {projectSkills && (
                <Typography className={classes.previewText}>
                  <strong>Skills Utilized:</strong> {projectSkills}
                </Typography>
              )}
              {projectOutcome && (
                <Typography className={classes.previewText}>
                  <strong>Outcome:</strong> {projectOutcome}
                </Typography>
              )}
              {projectUrl && (
                <Typography className={classes.previewText}>
                  <strong>URL:</strong> {projectUrl}
                </Typography>
              )}
            </Box>
          );
        default:
          return (
            <Typography>
              Unknown qualification type: {qualificationType}
            </Typography>
          );
      }
    } else {
      // Edit mode (form fields)
      switch (qualificationType) {
        case "Military Service":
          return (
            <Grid container spacing={2}>
              {/* Branch */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Branch of Service"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  className={classes.editableField}
                  placeholder="e.g. U.S. Army"
                  value={details.branch || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "branch",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
              {/* Rank */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Rank"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  className={classes.editableField}
                  placeholder="e.g. Sergeant"
                  value={details.rank || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "rank",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
              {/* Start Date */}
              <Grid item xs={12} sm={6}>
                <Tooltip title="Select the start date of your service">
                  <TextField
                    label="Start Date"
                    variant="outlined"
                    type="date"
                    disabled={previewMode}
                    className={classes.editableField}
                    InputLabelProps={{ shrink: true }}
                    value={details.startDate || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        [
                          "additionalQualifications",
                          qualificationIndex,
                          "details",
                          "startDate",
                        ],
                        e.target.value
                      )
                    }
                  />
                </Tooltip>
              </Grid>
              {/* End Date */}
              <Grid item xs={12} sm={6}>
                <Tooltip title="Select the end date of your service">
                  <TextField
                    label="End Date"
                    variant="outlined"
                    type="date"
                    disabled={previewMode}
                    className={classes.editableField}
                    InputLabelProps={{ shrink: true }}
                    value={details.endDate || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        [
                          "additionalQualifications",
                          qualificationIndex,
                          "details",
                          "endDate",
                        ],
                        e.target.value
                      )
                    }
                  />
                </Tooltip>
              </Grid>
              {/* Special Training */}
              <Grid item xs={12}>
                <TextField
                  label="Special Training or Skills"
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={2}
                  disabled={previewMode}
                  placeholder="e.g. Leadership courses, specialized weapon training"
                  className={classes.editableField}
                  value={details.specialTraining || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "specialTraining",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        case "Certification":
          return (
            <Grid container spacing={2}>
              {/* Credential Type */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Credential Type"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  className={classes.editableField}
                  placeholder="e.g. PMP Certification"
                  value={details.credentialType || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "credentialType",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
              {/* Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Credential Name"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  className={classes.editableField}
                  placeholder="e.g. Project Management Professional (PMP)"
                  value={details.name || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "name",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
              {/* Issuer */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Issuing Organization"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  className={classes.editableField}
                  placeholder="e.g. PMI"
                  value={details.issuer || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "issuer",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
              {/* Date Obtained */}
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Date Obtained"
                  variant="outlined"
                  type="date"
                  disabled={previewMode}
                  className={classes.editableField}
                  InputLabelProps={{ shrink: true }}
                  value={details.dateObtained || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "dateObtained",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
              {/* Expiration Date */}
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Expiration Date"
                  variant="outlined"
                  type="date"
                  disabled={previewMode}
                  className={classes.editableField}
                  InputLabelProps={{ shrink: true }}
                  value={details.expirationDate || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "expirationDate",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
              {/* Description */}
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  disabled={previewMode}
                  minRows={2}
                  className={classes.editableField}
                  placeholder="Briefly describe the certification and its relevance"
                  value={details.description || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "description",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        case "Personal Project":
          return (
            <Grid container spacing={2}>
              {/* Project Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Project Name"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  className={classes.editableField}
                  placeholder="e.g. Personal Blog Website"
                  value={details.projectName || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "projectName",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
              {/* Description */}
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={3}
                  disabled={previewMode}
                  className={classes.editableField}
                  placeholder="Briefly describe the project’s goal and process"
                  value={details.description || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "description",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
              {/* Skills Utilized */}
              <Grid item xs={12}>
                <TextField
                  label="Skills Utilized"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  className={classes.editableField}
                  placeholder="e.g. React, Node.js"
                  value={details.skills.join(", ") || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "skills",
                      ],
                      e.target.value.split(",").map((s) => s.trim())
                    )
                  }
                />
              </Grid>
              {/* Outcome */}
              <Grid item xs={12}>
                <TextField
                  label="Project Outcome"
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={2}
                  disabled={previewMode}
                  className={classes.editableField}
                  placeholder="Describe the results or what you learned"
                  value={details.outcome || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "outcome",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
              {/* Project URL */}
              <Grid item xs={12}>
                <TextField
                  label="Project URL"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  className={classes.editableField}
                  placeholder="http://myproject.com"
                  value={details.projectUrl || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      [
                        "additionalQualifications",
                        qualificationIndex,
                        "details",
                        "projectUrl",
                      ],
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        default:
          return (
            <Typography>
              Unknown qualification type: {qualificationType}
            </Typography>
          );
      }
    }
  };

  const { personalInformation } = content;
  const {
    firstName,
    lastName,
    email,
    phone,
    address,
    linkedinUrl,
    githubUrl,
    portfolioUrl,
    professionalSummary,
  } = personalInformation || {};

  const skillsStr = (content.skills || []).filter((s) => s).join(", ");

  return (
    <Box>
      {/* Preview Mode Toggle */}
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <FormControlLabel
          control={
            <Switch
              checked={previewMode}
              onChange={() => setPreviewMode(!previewMode)}
              color="primary"
            />
          }
          label="Preview Mode"
        />
      </Box>

      {/* Personal Information */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h5" className={classes.sectionTitle}>
          <Box display="flex" alignItems="center">
            <PersonIcon
              color="primary"
              style={{ marginRight: theme.spacing(1) }}
            />
            Personal Information
          </Box>
        </Typography>
        {previewMode ? (
          <Box>
            <Typography className={classes.previewText} variant="h6">
              {`${firstName || ""} ${lastName || ""}`.trim() ||
                "Name Not Provided"}
            </Typography>
            {email && (
              <Typography className={classes.previewText}>{email}</Typography>
            )}
            {phone && (
              <Typography className={classes.previewText}>{phone}</Typography>
            )}
            {address && (
              <Typography className={classes.previewText}>{address}</Typography>
            )}
            {linkedinUrl && (
              <Typography className={classes.previewText}>
                {linkedinUrl}
              </Typography>
            )}
            {githubUrl && (
              <Typography className={classes.previewText}>
                {githubUrl}
              </Typography>
            )}
            {portfolioUrl && (
              <Typography className={classes.previewText}>
                {portfolioUrl}
              </Typography>
            )}
          </Box>
        ) : (
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <Tooltip title="Enter your given name">
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  multiline
                  minRows={1}
                  maxRows={4}
                  className={classes.editableField}
                  placeholder="e.g. John"
                  value={firstName || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      ["personalInformation", "firstName"],
                      e.target.value
                    )
                  }
                />
              </Tooltip>
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <Tooltip title="Enter your family name">
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  multiline
                  minRows={1}
                  maxRows={4}
                  className={classes.editableField}
                  placeholder="e.g. Doe"
                  value={lastName || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      ["personalInformation", "lastName"],
                      e.target.value
                    )
                  }
                />
              </Tooltip>
            </Grid>
            {/* Email */}
            <Grid item xs={12} sm={6}>
              <Tooltip title="Enter your email address">
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  multiline
                  minRows={1}
                  maxRows={4}
                  className={classes.editableField}
                  placeholder="e.g. john.doe@example.com"
                  value={email || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      ["personalInformation", "email"],
                      e.target.value
                    )
                  }
                />
              </Tooltip>
            </Grid>
            {/* Phone */}
            <Grid item xs={12} sm={6}>
              <Tooltip title="Enter your contact number">
                <TextField
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  multiline
                  minRows={1}
                  maxRows={4}
                  className={classes.editableField}
                  placeholder="e.g. (123) 456-7890"
                  value={phone || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      ["personalInformation", "phone"],
                      e.target.value
                    )
                  }
                />
              </Tooltip>
            </Grid>
            {/* Address */}
            <Grid item xs={12}>
              <Tooltip title="Enter your full mailing address">
                <TextField
                  label="Address"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  multiline
                  minRows={2}
                  maxRows={6}
                  className={classes.editableField}
                  placeholder="e.g. 123 Main St, Springfield, USA"
                  value={address || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      ["personalInformation", "address"],
                      e.target.value
                    )
                  }
                />
              </Tooltip>
            </Grid>
            {/* LinkedIn URL */}
            <Grid item xs={12}>
              <Tooltip title="Enter your LinkedIn profile URL">
                <TextField
                  label="LinkedIn URL"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  multiline
                  minRows={1}
                  maxRows={4}
                  className={classes.editableField}
                  placeholder="https://www.linkedin.com/in/yourprofile"
                  value={linkedinUrl || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      ["personalInformation", "linkedinUrl"],
                      e.target.value
                    )
                  }
                />
              </Tooltip>
            </Grid>
            {/* GitHub URL */}
            <Grid item xs={12}>
              <Tooltip title="Enter your GitHub profile URL (optional)">
                <TextField
                  label="GitHub URL"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  multiline
                  minRows={1}
                  maxRows={4}
                  className={classes.editableField}
                  placeholder="https://github.com/yourusername"
                  value={githubUrl || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      ["personalInformation", "githubUrl"],
                      e.target.value
                    )
                  }
                />
              </Tooltip>
            </Grid>
            {/* Portfolio URL */}
            <Grid item xs={12}>
              <Tooltip title="Enter your personal website or portfolio URL (optional)">
                <TextField
                  label="Portfolio URL"
                  variant="outlined"
                  fullWidth
                  disabled={previewMode}
                  multiline
                  minRows={1}
                  maxRows={4}
                  className={classes.editableField}
                  placeholder="https://www.your-portfolio.com"
                  value={portfolioUrl || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      ["personalInformation", "portfolioUrl"],
                      e.target.value
                    )
                  }
                />
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </Box>

      {/* Professional Summary */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Professional Summary
        </Typography>
        {previewMode ? (
          <Typography className={classes.previewText}>
            {professionalSummary?.trim() || "No summary provided."}
          </Typography>
        ) : (
          <Tooltip title="Summarize your career, key strengths, and what makes you stand out">
            <TextField
              variant="outlined"
              fullWidth
              multiline
              disabled={previewMode}
              minRows={3}
              maxRows={10}
              className={classes.editableField}
              placeholder="Write a short summary highlighting your key skills and experience"
              value={professionalSummary || ""}
              onChange={(e) =>
                handleFieldChange(
                  ["personalInformation", "professionalSummary"],
                  e.target.value
                )
              }
            />
          </Tooltip>
        )}
      </Box>

      {/* Work Experience */}
      {content.workExperience && content.workExperience.length > 0 && (
        <Box className={classes.sectionContainer}>
          <Typography variant="h5" className={classes.sectionTitle}>
            <Box display="flex" alignItems="center">
              <WorkIcon
                color="primary"
                style={{ marginRight: theme.spacing(1) }}
              />
              Work Experience
            </Box>
          </Typography>
          {content.workExperience.map((job, index) => {
            const {
              jobTitle,
              companyName,
              location,
              startDate,
              endDate,
              responsibilities,
            } = job;

            return (
              <Box key={index} className={classes.workExperienceItem}>
                {index > 0 && <Divider className={classes.divider} />}
                <Box className={classes.arrayItemHeader}>
                  <Typography variant="h6">Position {index + 1}</Typography>
                  {!previewMode && (
                    <IconButton
                      onClick={() =>
                        handleRemoveArrayItem(["workExperience"], index)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
                {previewMode ? (
                  <Box>
                    {(jobTitle || companyName) && (
                      <Typography className={classes.previewText}>
                        <strong>{jobTitle?.trim() || "Untitled Role"}</strong>{" "}
                        {companyName ? `at ${companyName.trim()}` : ""}
                      </Typography>
                    )}
                    {location && (
                      <Typography className={classes.previewText}>
                        {location}
                      </Typography>
                    )}
                    {(startDate || endDate) && (
                      <Typography className={classes.previewText}>
                        {startDate} - {endDate || "Present"}
                      </Typography>
                    )}
                    {responsibilities && responsibilities.length > 0 && (
                      <List className={classes.bulletList}>
                        {responsibilities
                          .filter((r) => r && r.trim())
                          .map((resp, idx) => (
                            <ListItem key={idx} className={classes.bulletItem}>
                              {resp}
                            </ListItem>
                          ))}
                      </List>
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Grid container spacing={2}>
                      {/* Job Title */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Job Title"
                          variant="outlined"
                          fullWidth
                          multiline
                          disabled={previewMode}
                          minRows={1}
                          maxRows={4}
                          className={classes.editableField}
                          placeholder="e.g. Software Engineer"
                          value={jobTitle || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              ["workExperience", index, "jobTitle"],
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      {/* Company Name */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Company Name"
                          variant="outlined"
                          fullWidth
                          multiline
                          disabled={previewMode}
                          minRows={1}
                          maxRows={4}
                          className={classes.editableField}
                          placeholder="e.g. ABC Corp"
                          value={companyName || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              ["workExperience", index, "companyName"],
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      {/* Location */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Location"
                          variant="outlined"
                          fullWidth
                          multiline
                          disabled={previewMode}
                          minRows={1}
                          maxRows={4}
                          className={classes.editableField}
                          placeholder="e.g. New York, NY"
                          value={location || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              ["workExperience", index, "location"],
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      {/* Start Date */}
                      <Grid item xs={12} sm={3}>
                        <TextField
                          label="Start Date"
                          variant="outlined"
                          type="date"
                          disabled={previewMode}
                          className={classes.editableField}
                          InputLabelProps={{ shrink: true }}
                          value={startDate || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              ["workExperience", index, "startDate"],
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      {/* End Date */}
                      <Grid item xs={12} sm={3}>
                        <TextField
                          label="End Date"
                          variant="outlined"
                          type="date"
                          disabled={previewMode}
                          className={classes.editableField}
                          InputLabelProps={{ shrink: true }}
                          value={endDate || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              ["workExperience", index, "endDate"],
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    {/* Responsibilities */}
                    <Typography className={classes.fieldLabel}>
                      Responsibilities:
                    </Typography>
                    <List className={classes.bulletList}>
                      {responsibilities.map((resp, idx) => (
                        <ListItem key={idx} style={{ paddingLeft: 0 }}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            disabled={previewMode}
                            minRows={1}
                            maxRows={6}
                            className={classes.editableField}
                            placeholder="Describe a key responsibility or achievement"
                            value={resp || ""}
                            onChange={(e) =>
                              handleFieldChange(
                                [
                                  "workExperience",
                                  index,
                                  "responsibilities",
                                  idx,
                                ],
                                e.target.value
                              )
                            }
                          />
                          {!previewMode && (
                            <IconButton
                              onClick={() =>
                                handleRemoveArrayItem(
                                  ["workExperience", index, "responsibilities"],
                                  idx
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </ListItem>
                      ))}
                    </List>
                    {!previewMode && (
                      <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() =>
                          handleAddArrayItem(
                            ["workExperience", index, "responsibilities"],
                            ""
                          )
                        }
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: theme.spacing(1) }}
                      >
                        Add Responsibility
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            );
          })}
          {!previewMode && (
            <Button
              startIcon={<AddCircleOutlineIcon />}
              onClick={() =>
                handleAddArrayItem(["workExperience"], {
                  jobTitle: "",
                  companyName: "",
                  location: "",
                  startDate: "",
                  endDate: "",
                  responsibilities: [""],
                })
              }
              variant="outlined"
              color="primary"
              style={{ marginTop: theme.spacing(2) }}
            >
              Add Work Experience
            </Button>
          )}
        </Box>
      )}

      {/* Education */}
      {content.education && content.education.length > 0 && (
        <Box className={classes.sectionContainer}>
          <Typography variant="h5" className={classes.sectionTitle}>
            <Box display="flex" alignItems="center">
              <SchoolIcon
                color="primary"
                style={{ marginRight: theme.spacing(1) }}
              />
              Education
            </Box>
          </Typography>
          {content.education.map((edu, index) => {
            const {
              degree,
              fieldOfStudy,
              minor,
              institution,
              location,
              graduationDate,
            } = edu;

            return (
              <Box key={index}>
                {index > 0 && <Divider className={classes.divider} />}
                <Box className={classes.arrayItemHeader}>
                  <Typography variant="h6">Education {index + 1}</Typography>
                  {!previewMode && (
                    <IconButton
                      onClick={() =>
                        handleRemoveArrayItem(["education"], index)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
                {previewMode ? (
                  <Box>
                    {(degree || fieldOfStudy) && (
                      <Typography className={classes.previewText}>
                        <strong>
                          {degree?.trim() || "Degree not specified"}
                        </strong>
                        {fieldOfStudy ? ` in ${fieldOfStudy.trim()}` : ""}
                      </Typography>
                    )}
                    {minor && (
                      <Typography className={classes.previewText}>
                        Minor: {minor}
                      </Typography>
                    )}
                    {institution && (
                      <Typography className={classes.previewText}>
                        {institution}
                      </Typography>
                    )}
                    {location && (
                      <Typography className={classes.previewText}>
                        {location}
                      </Typography>
                    )}
                    {graduationDate && (
                      <Typography className={classes.previewText}>
                        Graduated: {graduationDate}
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <Grid container spacing={2}>
                    {/* Degree */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Degree"
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled={previewMode}
                        minRows={1}
                        maxRows={4}
                        className={classes.editableField}
                        placeholder="e.g. Bachelor of Science"
                        value={degree || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            ["education", index, "degree"],
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    {/* Field of Study */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Field of Study"
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled={previewMode}
                        minRows={1}
                        maxRows={4}
                        className={classes.editableField}
                        placeholder="e.g. Computer Science"
                        value={fieldOfStudy || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            ["education", index, "fieldOfStudy"],
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    {/* Minor */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Minor"
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled={previewMode}
                        minRows={1}
                        maxRows={4}
                        className={classes.editableField}
                        placeholder="e.g. Mathematics (optional)"
                        value={minor || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            ["education", index, "minor"],
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    {/* Institution */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Institution"
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled={previewMode}
                        minRows={1}
                        maxRows={4}
                        className={classes.editableField}
                        placeholder="e.g. University of XYZ"
                        value={institution || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            ["education", index, "institution"],
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    {/* Location */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Location"
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled={previewMode}
                        minRows={1}
                        maxRows={4}
                        className={classes.editableField}
                        placeholder="e.g. Boston, MA"
                        value={location || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            ["education", index, "location"],
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    {/* Graduation Date */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Graduation Date"
                        variant="outlined"
                        type="date"
                        disabled={previewMode}
                        className={classes.editableField}
                        InputLabelProps={{ shrink: true }}
                        value={graduationDate || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            ["education", index, "graduationDate"],
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            );
          })}
          {!previewMode && (
            <Button
              startIcon={<AddCircleOutlineIcon />}
              onClick={() =>
                handleAddArrayItem(["education"], {
                  degree: "",
                  fieldOfStudy: "",
                  minor: "",
                  institution: "",
                  location: "",
                  graduationDate: "",
                })
              }
              variant="outlined"
              color="primary"
              style={{ marginTop: theme.spacing(2) }}
            >
              Add Education
            </Button>
          )}
        </Box>
      )}

      {/* Skills */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h5" className={classes.sectionTitle}>
          <Box display="flex" alignItems="center">
            <StarIcon
              color="primary"
              style={{ marginRight: theme.spacing(1) }}
            />
            Skills
          </Box>
        </Typography>
        {previewMode ? (
          skillsStr ? (
            <Typography className={classes.previewText}>{skillsStr}</Typography>
          ) : (
            <Typography className={classes.previewText}>
              No skills provided.
            </Typography>
          )
        ) : (
          <Tooltip title="List your skills, separated by commas">
            <TextField
              variant="outlined"
              fullWidth
              multiline
              disabled={previewMode}
              minRows={1}
              maxRows={4}
              className={classes.editableField}
              placeholder="e.g. JavaScript, React, Node.js"
              value={skillsStr}
              onChange={(e) =>
                handleFieldChange(
                  ["skills"],
                  e.target.value.split(",").map((s) => s.trim())
                )
              }
            />
          </Tooltip>
        )}
      </Box>

      {/* Additional Qualifications */}
      {content.additionalQualifications &&
        content.additionalQualifications.length > 0 && (
          <Box className={classes.sectionContainer}>
            <Typography variant="h5" className={classes.sectionTitle}>
              Additional Qualifications
            </Typography>
            {content.additionalQualifications.map((qualification, index) => (
              <Box key={index}>
                {index > 0 && <Divider className={classes.divider} />}
                <Box className={classes.arrayItemHeader}>
                  <Typography variant="h6" className={classes.fieldLabel}>
                    {qualification.qualificationType}
                  </Typography>
                  {!previewMode && (
                    <IconButton
                      onClick={() =>
                        handleRemoveArrayItem(
                          ["additionalQualifications"],
                          index
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
                {renderAdditionalQualification(qualification, index)}
              </Box>
            ))}
          </Box>
        )}

      {/* Download Button */}
      <Box className={classes.buttonGroup}>
        <DownloadButton
          onClick={() =>
            handlePrintResume(content, "resume_template_1.docx", "resume.docx")
          }
          downloading={downloading}
          label="Download Resume"
          startIcon={<GetAppIcon />}
        />
      </Box>
    </Box>
  );
};

export default Resume;
