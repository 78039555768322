import React, { useState, useEffect, Suspense, lazy } from "react";
import Meta from "./../components/Meta";
import { useDarkMode } from "../util/theme";
import { Fade } from "react-awesome-reveal";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CircularProgress from "@material-ui/core/CircularProgress";

// Lazy load components for better performance
const HeroSection = lazy(() => import("./../components/HeroSection"));
const PoweredBy = lazy(() => import("../components/PoweredBy"));
const FeaturesSection = lazy(() => import("./../components/FeaturesSection"));
const TestimonialsSection = lazy(() =>
  import("../components/TestimonialsSection")
);
const NewsletterSection = lazy(() => import("../components/NewsletterSection"));
const FaqSection = lazy(() => import("../components/FaqSection"));
const HiredAtSection = lazy(() => import("../components/HiredAtSection")); // Newly added

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  scrollTopButton: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(3),
    width: 70,
    height: 70,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    border: "none",
    borderRadius: "50%",
    boxShadow: theme.shadows[5],
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
    zIndex: 1500,
    pointerEvents: "none",
    "&.visible": {
      opacity: 1,
      pointerEvents: "auto",
    },
    [theme.breakpoints.down(1500)]: {
      bottom: theme.spacing(12),
    },
    [theme.breakpoints.down(880)]: {
      bottom: theme.spacing(14),
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonIcon: {
    fontSize: "2.5rem",
  },
}));

function IndexPage(props) {
  const classes = useStyles();
  const { value: isDarkMode } = useDarkMode();
  const isDesktop = useMediaQuery("(min-width:960px)");

  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  // Handle scroll to show/hide the "Back to Top" button
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTopButton(window.pageYOffset > 100);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to top handler
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Meta
        title="Accelerate Your Job Search with AI-Powered Applications"
        description="Instantly craft tailored resumes and cover letters using AI. Stand out to recruiters and land your dream job faster."
      />
      <main>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "2rem",
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Fade triggerOnce>
            <HeroSection
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title={
                <>
                  Accelerate Your Job Search with
                  <br />
                  AI-Powered Applications
                </>
              }
              subtitle="Instantly craft tailored resumes and cover letters using AI. Get noticed by recruiters and secure your dream job faster."
              buttonText="Get Started Now"
              buttonColor="primary"
              buttonPath="/pro"
              showFlameAnimation={isDesktop}
            />
          </Fade>

          <Fade triggerOnce>
            <HiredAtSection
              bgColor="default"
              size="small"
              bgImage=""
              bgImageOpacity={1}
            />
          </Fade>

          <Fade triggerOnce>
            <FeaturesSection
              bgColor="light"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title="Unlock Your Potential"
              subtitle="Experience a smarter, faster way to land your next job"
              buttonText="Discover More"
              buttonColor="primary"
              buttonPath="/pro"
            />
          </Fade>
          <Fade triggerOnce>
            <PoweredBy
              bgColor="default"
              size="normal"
              bgImage=""
              bgImageOpacity={1}
              title="Powered by Cutting-Edge AI Technology"
              subtitle="Harness the world's most advanced AI models to create exceptional resumes and cover letters that stand out."
            />
          </Fade>
          <Fade triggerOnce>
            <TestimonialsSection
              bgColor="light"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title="Success Stories"
              subtitle="See how we've helped thousands secure their dream jobs"
            />
          </Fade>
          <Fade triggerOnce>
            <FaqSection
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title="Frequently Asked Questions"
              subtitle="Find answers to common questions about our AI-powered tools"
            />
          </Fade>
          <Fade triggerOnce>
            <NewsletterSection
              bgColor="light"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title="Join Our Newsletter"
              subtitle="Get the latest job search tips and feature updates"
              buttonText="Subscribe"
              buttonColor="primary"
              inputPlaceholder="Enter your email"
              subscribedMessage="You are now subscribed!"
            />
          </Fade>
        </Suspense>
      </main>
      {/* "Back to Top" Button */}
      <button
        onClick={scrollToTop}
        className={`${classes.scrollTopButton} ${
          showScrollTopButton ? "visible" : ""
        }`}
        aria-label="Back to Top"
      >
        <ArrowUpwardIcon className={classes.buttonIcon} />
      </button>
    </>
  );
}

export default React.memo(IndexPage);
