import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import DownloadButton from "./DownloadButton"; // Importing the DownloadButton component

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.text.primary,
  },
  editableField: { marginBottom: theme.spacing(2) },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  narrowTextField: {
    maxWidth: 600,
    width: "100%",
  },
  signatureSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  previewText: {
    marginBottom: theme.spacing(1),
    whiteSpace: "pre-wrap",
  },
}));

const CoverLetter = ({
  content,
  onContentChange,
  personalInformation,
  onPersonalInfoChange,
  handlePrintResume,
  downloading,
}) => {
  const classes = useStyles();
  const [previewMode, setPreviewMode] = useState(false);

  if (!content || typeof content !== "object")
    return <Typography>Invalid cover letter data</Typography>;

  const handleContentChange = (field, value) => {
    onContentChange({ ...content, [field]: value });
  };

  const { firstName, lastName, address, phone, email, linkedinUrl } =
    personalInformation || {};

  const {
    recipientName,
    recipientTitle,
    companyName,
    greeting,
    openingParagraph,
    bodyParagraphs,
    closingParagraph,
    closingPhrase = "Sincerely,",
    signatureName = `${firstName || ""} ${lastName || ""}`.trim(),
  } = content;

  return (
    <Box>
      {/* Preview Mode Toggle */}
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <FormControlLabel
          control={
            <Switch
              checked={previewMode}
              onChange={() => setPreviewMode(!previewMode)}
              color="primary"
            />
          }
          label="Preview Mode"
        />
      </Box>

      {/* Header */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Header
        </Typography>
        {previewMode ? (
          <Box>
            {/* Display personal info if available */}
            <Typography className={classes.previewText} variant="h6">
              {`${firstName || ""} ${lastName || ""}`.trim() ||
                "Name Not Provided"}
            </Typography>
            {address && (
              <Typography className={classes.previewText}>{address}</Typography>
            )}
            {phone && (
              <Typography className={classes.previewText}>{phone}</Typography>
            )}
            {email && (
              <Typography className={classes.previewText}>{email}</Typography>
            )}
            {linkedinUrl && (
              <Typography className={classes.previewText}>
                {linkedinUrl}
              </Typography>
            )}
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                className={classes.editableField}
                value={firstName || ""}
                onChange={(e) =>
                  onPersonalInfoChange("firstName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                className={classes.editableField}
                value={lastName || ""}
                onChange={(e) =>
                  onPersonalInfoChange("lastName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                className={classes.editableField}
                value={address || ""}
                onChange={(e) =>
                  onPersonalInfoChange("address", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                className={classes.editableField}
                value={phone || ""}
                onChange={(e) => onPersonalInfoChange("phone", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                className={classes.editableField}
                value={email || ""}
                onChange={(e) => onPersonalInfoChange("email", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="LinkedIn URL"
                variant="outlined"
                fullWidth
                className={classes.editableField}
                value={linkedinUrl || ""}
                onChange={(e) =>
                  onPersonalInfoChange("linkedinUrl", e.target.value)
                }
              />
            </Grid>
          </Grid>
        )}
      </Box>

      {/* Recipient Information */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Recipient Information
        </Typography>
        {previewMode ? (
          <Box>
            {recipientName && (
              <Typography className={classes.previewText}>
                {recipientName}
              </Typography>
            )}
            {recipientTitle && (
              <Typography className={classes.previewText}>
                {recipientTitle}
              </Typography>
            )}
            {companyName && (
              <Typography className={classes.previewText}>
                {companyName}
              </Typography>
            )}
          </Box>
        ) : (
          <>
            <TextField
              label="Recipient Name"
              variant="outlined"
              fullWidth
              className={`${classes.editableField} ${classes.narrowTextField}`}
              value={recipientName || ""}
              onChange={(e) =>
                handleContentChange("recipientName", e.target.value)
              }
            />
            <TextField
              label="Recipient Title"
              variant="outlined"
              fullWidth
              className={`${classes.editableField} ${classes.narrowTextField}`}
              value={recipientTitle || ""}
              onChange={(e) =>
                handleContentChange("recipientTitle", e.target.value)
              }
            />
            <TextField
              label="Company Name"
              variant="outlined"
              fullWidth
              className={`${classes.editableField} ${classes.narrowTextField}`}
              value={companyName || ""}
              onChange={(e) =>
                handleContentChange("companyName", e.target.value)
              }
            />
          </>
        )}
      </Box>

      {/* Greeting */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Greeting
        </Typography>
        {previewMode ? (
          <Typography className={classes.previewText}>
            {greeting?.trim() || "Dear [Name]:"}
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            fullWidth
            className={classes.editableField}
            placeholder="e.g. Dear [Name],"
            value={greeting || ""}
            onChange={(e) => handleContentChange("greeting", e.target.value)}
          />
        )}
      </Box>

      {/* Opening Paragraph */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Opening Paragraph
        </Typography>
        {previewMode ? (
          <Typography className={classes.previewText}>
            {openingParagraph?.trim() || "No opening paragraph provided."}
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            className={classes.editableField}
            value={openingParagraph || ""}
            onChange={(e) =>
              handleContentChange("openingParagraph", e.target.value)
            }
          />
        )}
      </Box>

      {/* Body Paragraphs */}
      {bodyParagraphs && bodyParagraphs.length > 0 && (
        <Box className={classes.sectionContainer}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Body Paragraphs
          </Typography>
          {previewMode ? (
            <Box>
              {bodyParagraphs.map((para, index) =>
                para?.trim() ? (
                  <Typography key={index} className={classes.previewText}>
                    {para}
                  </Typography>
                ) : null
              )}
            </Box>
          ) : (
            bodyParagraphs.map((para, index) => (
              <TextField
                key={index}
                variant="outlined"
                fullWidth
                multiline
                minRows={3}
                className={classes.editableField}
                value={para || ""}
                onChange={(e) => {
                  const updatedBodyParagraphs = [...bodyParagraphs];
                  updatedBodyParagraphs[index] = e.target.value;
                  handleContentChange("bodyParagraphs", updatedBodyParagraphs);
                }}
              />
            ))
          )}
        </Box>
      )}

      {/* Closing Paragraph */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Closing Paragraph
        </Typography>
        {previewMode ? (
          <Typography className={classes.previewText}>
            {closingParagraph?.trim() || "No closing paragraph provided."}
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            className={classes.editableField}
            value={closingParagraph || ""}
            onChange={(e) =>
              handleContentChange("closingParagraph", e.target.value)
            }
          />
        )}
      </Box>

      {/* Signature */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Signature
        </Typography>
        {previewMode ? (
          <Box>
            <Typography className={classes.previewText}>
              {closingPhrase?.trim() || "Sincerely,"}
            </Typography>
            <Box className={classes.signatureSpacing} />
            <Typography className={classes.previewText}>
              {signatureName || "Your Name"}
            </Typography>
          </Box>
        ) : (
          <>
            <TextField
              label="Closing Phrase"
              variant="outlined"
              fullWidth
              className={classes.editableField}
              value={closingPhrase || "Sincerely,"}
              onChange={(e) =>
                handleContentChange("closingPhrase", e.target.value)
              }
            />
            <Box className={classes.signatureSpacing} />
            <TextField
              label="Your Name"
              variant="outlined"
              fullWidth
              className={classes.editableField}
              value={signatureName}
              onChange={(e) =>
                handleContentChange("signatureName", e.target.value)
              }
            />
          </>
        )}
      </Box>

      {/* Download Button */}
      <Box className={classes.buttonGroup}>
        <DownloadButton
          onClick={() =>
            handlePrintResume(
              content,
              "cover_letter_template_1.docx",
              "cover_letter.docx",
              { personalInformation: personalInformation }
            )
          }
          downloading={downloading}
          label="Download Cover Letter"
          startIcon={<GetAppIcon />}
        />
      </Box>
    </Box>
  );
};

export default CoverLetter;
