// InterviewPreparation.js

import React, { useState } from "react";
import { Box, Typography, Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FlipIcon from "@material-ui/icons/Flip";

const useStyles = makeStyles((theme) => ({
  qaCard: {
    width: "100%",
    maxWidth: "500px",
    height: "300px",
    perspective: "1000px",
    marginBottom: theme.spacing(4),
    cursor: "pointer",
  },
  qaCardInner: {
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    transition: "transform 0.8s",
    transformStyle: "preserve-3d",
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
  },
  qaCardFlipped: {
    transform: "rotateY(180deg)",
  },
  qaCardSide: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    boxSizing: "border-box",
  },
  qaCardFront: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  qaCardBack: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: "rotateY(180deg)",
  },
  qaCardContent: {
    fontSize: "1rem",
    lineHeight: 1.5,
  },
  qaFlipIndicator: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    opacity: 0.8,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
    boxShadow: theme.shadows[2],
    zIndex: 2,
  },
  qaFlipText: {
    marginLeft: theme.spacing(0.5),
    fontSize: "0.75rem",
  },
  navButton: {
    minWidth: "100px",
    margin: theme.spacing(0, 1),
  },
  boldText: { fontWeight: "bold" },
  tipsContainer: {
    padding: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
  },
  tipPaper: {
    padding: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  tipText: {
    fontSize: "1rem",
    color:
      theme.palette.type === "dark"
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
  },
}));

const QuestionAnswerCard = ({ question, answer, flipped, handleCardClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.qaCard} onClick={handleCardClick}>
      <Box
        className={`${classes.qaCardInner} ${
          flipped ? classes.qaCardFlipped : ""
        }`}
      >
        {/* Front Side - Question */}
        <Box className={`${classes.qaCardSide} ${classes.qaCardFront}`}>
          <Typography variant="h6" className={classes.qaCardContent}>
            <strong>QUESTION:</strong>
            <br /> {question}
          </Typography>
        </Box>
        {/* Back Side - Answer */}
        <Box className={`${classes.qaCardSide} ${classes.qaCardBack}`}>
          <Typography variant="body1" className={classes.qaCardContent}>
            <strong>ANSWER:</strong> <br /> {answer}
          </Typography>
        </Box>
      </Box>
      <div className={classes.qaFlipIndicator}>
        <FlipIcon fontSize="small" />
        <Typography variant="caption" className={classes.qaFlipText}>
          {flipped ? "Show Question" : "Show Answer"}
        </Typography>
      </div>
    </Box>
  );
};

const InterviewPreparation = ({ content }) => {
  const classes = useStyles();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [flipped, setFlipped] = useState(false);

  if (!content || typeof content !== "object")
    return <Typography>Invalid interview preparation data</Typography>;

  const totalQuestions = content.commonQuestions.length;

  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  const handleNext = () => {
    if (!flipped) {
      setFlipped(true);
    } else {
      setFlipped(false);
      if (currentQuestionIndex < totalQuestions - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }
  };

  const handlePrev = () => {
    if (!flipped) {
      setFlipped(true);
    } else {
      setFlipped(false);
      if (currentQuestionIndex > 0) {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {/* Question and Answer Card */}
      <QuestionAnswerCard
        question={content.commonQuestions[currentQuestionIndex]}
        answer={content.suggestedAnswers[currentQuestionIndex]}
        flipped={flipped}
        handleCardClick={handleCardClick}
      />

      {/* Question Indicator */}
      <Box mt={2}>
        <Typography variant="body2" className={classes.questionIndicator}>
          Question {currentQuestionIndex + 1} of {totalQuestions}
        </Typography>
      </Box>

      {/* Navigation Buttons */}
      <Box display="flex" justifyContent="center" mt={1} mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrev}
          disabled={currentQuestionIndex === 0 && !flipped}
          className={classes.navButton}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={currentQuestionIndex === totalQuestions - 1 && flipped}
          className={classes.navButton}
        >
          Next
        </Button>
      </Box>

      {/* Tips Section */}
      <Box mt={2} width="100%">
        <Typography className={classes.boldText} gutterBottom>
          Tips:
        </Typography>
        <Box className={classes.tipsContainer}>
          <Grid container spacing={2}>
            {content.tips && content.tips.length > 0 ? (
              content.tips.map((tip, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Paper elevation={3} className={classes.tipPaper}>
                    <Typography variant="body1" className={classes.tipText}>
                      <strong>{index + 1}: </strong> {tip}
                    </Typography>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography>No tips available.</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default InterviewPreparation;
