// src/components/GenerationsCalendar.jsx

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  memo,
} from "react";
import PropTypes from "prop-types";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { withStyles, useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  IconButton,
  Collapse,
  Divider,
  CircularProgress,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
  Box,
  Stepper,
  Step,
  StepLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Fab,
  Button,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import BusinessIcon from "@material-ui/icons/Business";
import {
  ExpandLess,
  ExpandMore,
  ArrowBack,
  ArrowForward,
  Today as TodayIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import applicationMaterials from "./applicationMaterials";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

// Initialize the localizer using moment.js
const localizer = momentLocalizer(moment);

// Drag and Drop Calendar
const DnDCalendar = withDragAndDrop(Calendar);

// Styled Components using Material-UI's withStyles
const styles = (theme) => ({
  calendarContainer: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
    position: "relative",
  },
  eventStyled: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
    fontSize: "0.875rem",
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dialogContentStyled: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  companyLogo: {
    marginRight: theme.spacing(2),
    width: theme.spacing(5),
    height: theme.spacing(5),
    objectFit: "contain",
  },
  listItemStyled: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  toolbarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& .rbc-toolbar-label": {
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: "1rem",
    },
    "& button": {
      color: theme.palette.text.primary,
      backgroundColor: "transparent",
      border: "none",
      fontSize: "0.875rem",
      margin: theme.spacing(0, 0.5),
      cursor: "pointer",
      padding: theme.spacing(0.5, 1),
      borderRadius: "4px",
      transition: "background-color 0.3s",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
    flexWrap: "wrap",
  },
  formControlStyled: {
    minWidth: 120,
  },
  alertSnackbar: {
    width: "100%",
  },
  progressContainer: {
    marginTop: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0),
  },
  radioGroupCentered: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  dividerStyled: {
    margin: theme.spacing(2, 0),
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  statsContainer: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
  },
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
});

// Helper function to construct domain from company name
const constructDomain = (companyName) => {
  if (!companyName) return "example.com";
  const sanitized = companyName.toLowerCase().replace(/[^a-z0-9]/g, "");
  return `${sanitized}.com`;
};

// Function to get logo URL using Clearbit Logo API with fallback
const getLogoUrl = (companyName) => {
  const domain = constructDomain(companyName);
  return `https://logo.clearbit.com/${domain}`;
};

// Alert Component for Snackbar
const AlertComponent = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Custom Toolbar Component
const CustomToolbar = ({ onNavigate, label }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const goToBack = useCallback(() => {
    onNavigate("PREV");
  }, [onNavigate]);

  const goToNext = useCallback(() => {
    onNavigate("NEXT");
  }, [onNavigate]);

  const goToToday = useCallback(() => {
    onNavigate("TODAY");
  }, [onNavigate]);

  return (
    <Box className="toolbarContainer">
      <Box display="flex" alignItems="center">
        <IconButton
          onClick={goToBack}
          aria-label="Previous"
          size={isMobile ? "small" : "medium"}
        >
          <ArrowBack />
        </IconButton>
        <IconButton
          onClick={goToToday}
          aria-label="Today"
          size={isMobile ? "small" : "medium"}
        >
          <TodayIcon />
        </IconButton>
        <IconButton
          onClick={goToNext}
          aria-label="Next"
          size={isMobile ? "small" : "medium"}
        >
          <ArrowForward />
        </IconButton>
      </Box>
      <Typography variant="h6" aria-label="Current View">
        {label}
      </Typography>
    </Box>
  );
};

CustomToolbar.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

// Memoized ListItem component for better performance
const GenerationListItem = memo(function GenerationListItem({
  event,
  isExpanded,
  onClick,
  onDelete,
  onEdit,
  onProgressChange,
  applicationMaterials,
  onMaterialSelect,
  classes,
}) {
  const steps = useMemo(
    () => ["Applied", "Interview", "Offer", "Accepted"],
    []
  );

  const [activeStep, setActiveStep] = useState(event.generation.progress || 0);
  const [status, setStatus] = useState(event.generation.status || "active");

  useEffect(() => {
    setActiveStep(event.generation.progress || 0);
    setStatus(event.generation.status || "active");
  }, [event.generation.progress, event.generation.status]);

  const handleStepChange = useCallback(
    (step) => {
      setActiveStep(step);
      onProgressChange(event.generation.id, step, status);
    },
    [onProgressChange, event.generation.id, status]
  );

  const handleStatusChange = useCallback(
    (e) => {
      const newStatus = e.target.value;
      setStatus(newStatus);
      onProgressChange(event.generation.id, activeStep, newStatus);
    },
    [onProgressChange, event.generation.id, activeStep]
  );

  return (
    <React.Fragment key={event.id}>
      <ListItem
        button
        onClick={() => onClick(event.id)}
        aria-expanded={isExpanded}
        aria-controls={`generation-details-${event.id}`}
      >
        <ListItemIcon>
          <Avatar
            src={getLogoUrl(event.generation.companyName)}
            alt={`${event.generation.companyName} Logo`}
            style={{ marginRight: 16, width: 40, height: 40 }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "";
            }}
          >
            <BusinessIcon />
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={event.generation.companyName}
          secondary={event.generation.jobTitle}
        />
        <IconButton
          onClick={() => onEdit(event.generation.id)}
          aria-label="Edit generation"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="Delete generation"
          onClick={() => onDelete(event.generation.id)}
          color="secondary"
        >
          <DeleteIcon />
        </IconButton>
        {isExpanded ? (
          <ExpandLess aria-label="Collapse details" />
        ) : (
          <ExpandMore aria-label="Expand details" />
        )}
      </ListItem>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        id={`generation-details-${event.id}`}
      >
        <List component="div" disablePadding>
          {status === "active" && (
            <>
              <ListItem>
                <Typography variant="body1">Progress:</Typography>
              </ListItem>
              <ListItem>
                <Box display="flex" justifyContent="center" width="100%">
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    className={classes.stepper}
                  >
                    {steps.map((label, index) => (
                      <Step key={label} onClick={() => handleStepChange(index)}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </ListItem>
            </>
          )}
          <Box className={classes.radioGroupCentered}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="status"
                name="status"
                value={status}
                onChange={handleStatusChange}
              >
                <FormControlLabel
                  value="active"
                  control={<Radio color="primary" />}
                  label="Active"
                />
                <FormControlLabel
                  value="closed"
                  control={<Radio color="secondary" />}
                  label="Not Selected"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Divider className={classes.dividerStyled} />
          {Object.keys(applicationMaterials)
            .filter((key) => event.generation.content?.[`${key}Json`])
            .map((key) => {
              const MaterialIcon = applicationMaterials[key].icon;
              return (
                <ListItem
                  button
                  key={key}
                  onClick={() => onMaterialSelect(event, key)}
                  style={{ paddingLeft: 64 }}
                  aria-label={`Select ${applicationMaterials[key].title}`}
                >
                  <ListItemIcon>
                    <MaterialIcon />
                  </ListItemIcon>
                  <ListItemText primary={applicationMaterials[key].title} />
                </ListItem>
              );
            })}
        </List>
      </Collapse>
      <Divider component="li" />
    </React.Fragment>
  );
});

GenerationListItem.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    generation: PropTypes.shape({
      id: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      jobTitle: PropTypes.string.isRequired,
      content: PropTypes.object.isRequired,
      progress: PropTypes.number,
      status: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onProgressChange: PropTypes.func.isRequired,
  applicationMaterials: PropTypes.object.isRequired,
  onMaterialSelect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

// Main Component
function GenerationsCalendar({
  classes,
  generations,
  onSelectGeneration,
  onEditGeneration,
  onDeleteGeneration,
  onUpdateProgress,
  onAddGeneration, // New callback for adding a generation
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");

  // State management
  const [events, setEvents] = useState([]);
  const [selectedGenerations, setSelectedGenerations] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [expandedGenerations, setExpandedGenerations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("companyName");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // New states for sorting and filtering
  const [sortField, setSortField] = useState("companyName"); // companyName or jobTitle
  const [sortOrder, setSortOrder] = useState("asc"); // asc or desc
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");

  // For adding a new generation
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState("");
  const [newJobTitle, setNewJobTitle] = useState("");
  const [newTimestamp, setNewTimestamp] = useState("");

  // Memoize application materials to prevent unnecessary recalculations
  const memoizedApplicationMaterials = useMemo(() => applicationMaterials, []);

  // Effect to map generations to calendar events
  useEffect(() => {
    if (!Array.isArray(generations)) {
      console.error("Generations prop should be an array.");
      setEvents([]);
      setIsLoading(false);
      return;
    }

    const mappedEvents = generations
      .filter((generation) => {
        const timestamp = parseTimestamp(generation.timestamp);
        return (
          generation &&
          generation.id &&
          timestamp instanceof Date &&
          !isNaN(timestamp)
        );
      })
      .map((generation) => {
        // Correctly parse the timestamp
        const timestamp = parseTimestamp(generation.timestamp);
        const startDate = timestamp;
        const endDate = new Date(startDate.getTime());
        endDate.setHours(endDate.getHours() + 1); // Set default event duration to 1 hour

        const jobTitle = generation.jobTitle || "Job Title";
        const companyName = generation.companyName || "Company Name";

        return {
          id: generation.id,
          title: `${jobTitle} at ${companyName}`,
          start: startDate,
          end: endDate,
          allDay: true,
          generation,
        };
      });

    // Ensure no undefined events are added
    const validEvents = mappedEvents.filter(
      (event) => event && event.title && event.start && event.end
    );

    setEvents(validEvents);
    setIsLoading(false);
  }, [generations]);

  // Function to parse timestamp
  const parseTimestamp = (timestamp) => {
    if (!timestamp) return new Date();
    if (timestamp instanceof Date) return timestamp;
    if (typeof timestamp.toDate === "function") return timestamp.toDate(); // Firestore Timestamp
    if (typeof timestamp === "string") {
      const parsedDate = new Date(timestamp);
      if (!isNaN(parsedDate)) {
        return parsedDate;
      }
    }
    if (timestamp.seconds) return new Date(timestamp.seconds * 1000); // Firestore Timestamp in object form
    return new Date();
  };

  // Handler for selecting an event
  const handleSelectEvent = useCallback(
    (event) => {
      if (!event) return;
      // Select events that occur on the same day
      const selected = events.filter((e) =>
        moment(e.start).isSame(event.start, "day")
      );
      setSelectedGenerations(selected);
      setExpandedGenerations([]); // Reset expanded generations
      setDialogOpen(true);
    },
    [events]
  );

  // Handler for clicking '+N more'
  const handleShowMore = useCallback(
    (eventsToShow, date) => {
      if (!eventsToShow) return;
      // Set selectedGenerations to the events for that date
      const selected = events.filter((e) =>
        moment(e.start).isSame(date, "day")
      );
      setSelectedGenerations(selected);
      setExpandedGenerations([]); // Reset expanded generations
      setDialogOpen(true);
    },
    [events]
  );

  // Custom event styling based on progress and status
  const eventPropGetter = useCallback((event) => {
    if (!event || !event.generation) {
      return {};
    }
    let backgroundColor;
    if (event.generation.status === "closed") {
      backgroundColor = "#f44336"; // Not Selected (Closed)
    } else {
      switch (event.generation.progress) {
        case 0:
          backgroundColor = "#3f51b5"; // Applied
          break;
        case 1:
          backgroundColor = "#ff9800"; // Interview
          break;
        case 2:
          backgroundColor = "#4caf50"; // Offer
          break;
        case 3:
          backgroundColor = "#2e7d32"; // Accepted
          break;
        default:
          backgroundColor = "#3f51b5"; // Default
          break;
      }
    }
    return {
      style: {
        backgroundColor,
        color: "#fff",
        borderRadius: "8px",
        padding: "4px 8px",
        fontSize: "0.875rem",
        fontWeight: 500,
      },
    };
  }, []);

  // Handler for expanding/collapsing generation details
  const handleGenerationClick = useCallback((generationId) => {
    setExpandedGenerations((prev) =>
      prev.includes(generationId)
        ? prev.filter((id) => id !== generationId)
        : [...prev, generationId]
    );
  }, []);

  // Handler for progress and status change
  const handleProgressChange = useCallback(
    (generationId, step, status) => {
      onUpdateProgress(generationId, step, status);
      // Update the events state to reflect the new progress and status
      setEvents((prevEvents) =>
        prevEvents.map((event) => {
          if (event.generation.id === generationId) {
            return {
              ...event,
              generation: {
                ...event.generation,
                progress: step,
                status: status,
              },
            };
          }
          return event;
        })
      );
      // Update selectedGenerations state
      setSelectedGenerations((prevSelected) =>
        prevSelected.map((event) => {
          if (event.generation.id === generationId) {
            return {
              ...event,
              generation: {
                ...event.generation,
                progress: step,
                status: status,
              },
            };
          }
          return event;
        })
      );
      setSnackbar({
        open: true,
        message: "Job progress updated successfully!",
        severity: "success",
      });
    },
    [onUpdateProgress]
  );

  // Handler for selecting an application material
  const handleMaterialSelect = useCallback(
    (event, key) => {
      try {
        localStorage.setItem("selectedGenerationId", event.generation.id);
        localStorage.setItem("selectedApplicationMaterial", key);
        onSelectGeneration(event.generation.id, key);
        setDialogOpen(false);
        setSnackbar({
          open: true,
          message: "Generation selected successfully!",
          severity: "success",
        });
      } catch (error) {
        console.error("Error selecting generation:", error);
        setSnackbar({
          open: true,
          message: "Failed to select generation.",
          severity: "error",
        });
      }
    },
    [onSelectGeneration]
  );

  // Handler for search input change
  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  // Handler for filter type change
  const handleFilterTypeChange = useCallback((e) => {
    setFilterType(e.target.value);
  }, []);

  // Sorting and filtering
  const filteredEvents = useMemo(() => {
    let result = events;

    // Apply search filter
    if (searchTerm) {
      result = result.filter((event) =>
        event.generation[filterType]
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }

    // Apply date range filter
    if (startDateFilter) {
      const start = moment(startDateFilter, "YYYY-MM-DD");
      result = result.filter((event) =>
        moment(event.start).isSameOrAfter(start, "day")
      );
    }

    if (endDateFilter) {
      const end = moment(endDateFilter, "YYYY-MM-DD");
      result = result.filter((event) =>
        moment(event.start).isSameOrBefore(end, "day")
      );
    }

    // Apply sorting
    result = [...result].sort((a, b) => {
      let valA = a.generation[sortField]?.toString().toLowerCase() || "";
      let valB = b.generation[sortField]?.toString().toLowerCase() || "";
      if (valA < valB) return sortOrder === "asc" ? -1 : 1;
      if (valA > valB) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    return result;
  }, [
    events,
    searchTerm,
    filterType,
    sortField,
    sortOrder,
    startDateFilter,
    endDateFilter,
  ]);

  // Handler for closing snackbar
  const handleCloseSnackbar = useCallback((event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  // Determine views and default view based on device
  const calendarViews = useMemo(
    () => (isMobile ? [Views.AGENDA] : [Views.MONTH, Views.WEEK, Views.DAY]),
    [isMobile]
  );
  const defaultView = useMemo(
    () => (isMobile ? Views.AGENDA : Views.MONTH),
    [isMobile]
  );

  // Use a key that changes with the view to force re-mounting of the Calendar
  const calendarKey = useMemo(
    () => (isMobile ? "mobile" : "desktop"),
    [isMobile]
  );

  // Custom components to hide time column in all views
  const customComponents = useMemo(
    () => ({
      toolbar: CustomToolbar,
      event: ({ event }) => {
        if (!event || !event.title) {
          return null;
        }
        return (
          <div
            className={classes.eventStyled}
            role="button"
            tabIndex={0}
            aria-label={`Event: ${event.title}`}
          >
            {event.title}
          </div>
        );
      },
      agenda: {
        time: () => null,
        event: ({ event }) => {
          if (!event || !event.title) return null;
          return (
            <span>
              <strong>{event.title}</strong>
            </span>
          );
        },
      },
      day: {
        header: () => null,
      },
      week: {
        header: () => null,
      },
    }),
    [classes.eventStyled]
  );

  // Summarize statistics
  const stats = useMemo(() => {
    const total = filteredEvents.length;
    const active = filteredEvents.filter(
      (ev) => ev.generation.status !== "closed"
    ).length;
    const closed = filteredEvents.filter(
      (ev) => ev.generation.status === "closed"
    ).length;
    const applied = filteredEvents.filter(
      (ev) => ev.generation.progress === 0
    ).length;
    const interviews = filteredEvents.filter(
      (ev) => ev.generation.progress === 1
    ).length;
    const offers = filteredEvents.filter(
      (ev) => ev.generation.progress === 2
    ).length;
    const accepted = filteredEvents.filter(
      (ev) => ev.generation.progress === 3
    ).length;
    return { total, active, closed, applied, interviews, offers, accepted };
  }, [filteredEvents]);

  // Handle add new generation dialog
  const handleAddClick = useCallback(() => {
    setAddDialogOpen(true);
  }, []);

  const handleAddGeneration = useCallback(() => {
    if (!newCompanyName || !newJobTitle || !newTimestamp) {
      setSnackbar({
        open: true,
        message: "Please fill out all fields for the new generation.",
        severity: "error",
      });
      return;
    }

    const parsedDate = new Date(newTimestamp);
    if (isNaN(parsedDate)) {
      setSnackbar({
        open: true,
        message: "Invalid date format. Please use YYYY-MM-DD.",
        severity: "error",
      });
      return;
    }

    onAddGeneration({
      companyName: newCompanyName,
      jobTitle: newJobTitle,
      timestamp: parsedDate.toISOString(),
      content: {}, // default empty content
      status: "active",
      progress: 0,
    });

    setSnackbar({
      open: true,
      message: "New generation added successfully!",
      severity: "success",
    });

    setNewCompanyName("");
    setNewJobTitle("");
    setNewTimestamp("");
    setAddDialogOpen(false);
  }, [newCompanyName, newJobTitle, newTimestamp, onAddGeneration]);

  // Render loading state
  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress aria-label="Loading calendar" />
      </div>
    );
  }

  return (
    <>
      <div className={classes.calendarContainer}>
        {/* Statistics */}
        <div className={classes.statsContainer}>
          <Typography variant="h6" gutterBottom>
            Job Statistics
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <Typography variant="body1">
                <strong>Total:</strong> {stats.total}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body1">
                <strong>Active:</strong> {stats.active}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body1">
                <strong>Not Selected:</strong> {stats.closed}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body1">
                <strong>Applied:</strong> {stats.applied}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body1">
                <strong>Interviews:</strong> {stats.interviews}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body1">
                <strong>Offers:</strong> {stats.offers}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="body1">
                <strong>Accepted:</strong> {stats.accepted}
              </Typography>
            </Grid>
          </Grid>
        </div>

        {/* Search, Filter and Sort Controls */}
        <div className={classes.filterContainer}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            fullWidth={!isMobile}
            inputProps={{ "aria-label": "Search Generations" }}
          />
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControlStyled}
          >
            <InputLabel id="filter-type-label">Filter By</InputLabel>
            <Select
              labelId="filter-type-label"
              id="filter-type"
              value={filterType}
              onChange={handleFilterTypeChange}
              label="Filter By"
              inputProps={{ "aria-label": "Filter Type" }}
            >
              <MenuItem value="companyName">Company Name</MenuItem>
              <MenuItem value="jobTitle">Job Title</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Start Date (YYYY-MM-DD)"
            variant="outlined"
            size="small"
            value={startDateFilter}
            onChange={(e) => setStartDateFilter(e.target.value)}
            inputProps={{ "aria-label": "Start Date Filter" }}
          />
          <TextField
            label="End Date (YYYY-MM-DD)"
            variant="outlined"
            size="small"
            value={endDateFilter}
            onChange={(e) => setEndDateFilter(e.target.value)}
            inputProps={{ "aria-label": "End Date Filter" }}
          />

          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControlStyled}
          >
            <InputLabel id="sort-field-label">Sort By</InputLabel>
            <Select
              labelId="sort-field-label"
              id="sort-field"
              value={sortField}
              onChange={(e) => setSortField(e.target.value)}
              label="Sort By"
              inputProps={{ "aria-label": "Sort Field" }}
            >
              <MenuItem value="companyName">Company Name</MenuItem>
              <MenuItem value="jobTitle">Job Title</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControlStyled}
          >
            <InputLabel id="sort-order-label">Order</InputLabel>
            <Select
              labelId="sort-order-label"
              id="sort-order"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              label="Order"
              inputProps={{ "aria-label": "Sort Order" }}
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* Calendar Component */}
        <DnDCalendar
          key={calendarKey} // Add key prop
          localizer={localizer}
          events={filteredEvents}
          startAccessor="start"
          endAccessor="end"
          selectable
          onSelectEvent={handleSelectEvent}
          onShowMore={handleShowMore}
          style={{ height: "auto", minHeight: 500 }}
          eventPropGetter={eventPropGetter}
          popup
          views={calendarViews}
          defaultView={defaultView}
          toolbar
          onEventDrop={({ event, start, end }) => {
            onEditGeneration(event.generation.id, { start, end });
          }}
          onEventResize={({ event, start, end }) => {
            onEditGeneration(event.generation.id, { start, end });
          }}
          resizable
          components={customComponents}
          messages={{
            today: "Today",
            previous: "Back",
            next: "Next",
            month: "Month",
            week: "Week",
            day: "Day",
            agenda: "Agenda",
            date: "Date",
            time: "",
            event: "Event",
          }}
        />

        {/* Floating Action Button to Add New Generation */}
        {onAddGeneration && (
          <Fab
            color="primary"
            aria-label="Add New Generation"
            className={classes.fab}
            onClick={handleAddClick}
          >
            <AddIcon />
          </Fab>
        )}
      </div>

      {/* Generations Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="generations-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          disableTypography
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: theme.palette.background.default,
            borderBottom: "1px solid #ccc",
          }}
        >
          <Typography variant="h6" id="generations-dialog-title">
            Generated Content on{" "}
            {selectedGenerations[0]?.start
              ? moment(selectedGenerations[0].start).format("MMMM Do, YYYY")
              : ""}
          </Typography>
          <IconButton
            aria-label="Close dialog"
            onClick={() => setDialogOpen(false)}
            style={{ color: "#757575" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContentStyled}>
          {selectedGenerations.length === 0 ? (
            <Typography>No generations found for this date.</Typography>
          ) : (
            <List>
              {selectedGenerations.map((event) => (
                <GenerationListItem
                  key={event.id}
                  event={event}
                  isExpanded={expandedGenerations.includes(event.id)}
                  onClick={handleGenerationClick}
                  onDelete={onDeleteGeneration}
                  onEdit={onEditGeneration}
                  onProgressChange={handleProgressChange}
                  applicationMaterials={memoizedApplicationMaterials}
                  onMaterialSelect={handleMaterialSelect}
                  classes={classes}
                />
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>

      {/* Dialog for Adding New Generation */}
      <Dialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        aria-labelledby="add-generation-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="add-generation-dialog-title">
          Add New Generation
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newCompanyName}
            onChange={(e) => setNewCompanyName(e.target.value)}
          />
          <TextField
            label="Job Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newJobTitle}
            onChange={(e) => setNewJobTitle(e.target.value)}
          />
          <TextField
            label="Date (YYYY-MM-DD)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newTimestamp}
            onChange={(e) => setNewTimestamp(e.target.value)}
          />
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={() => setAddDialogOpen(false)} color="default">
            Cancel
          </Button>
          <Button onClick={handleAddGeneration} color="primary">
            Add
          </Button>
        </Box>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <AlertComponent
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          className={classes.alertSnackbar}
        >
          {snackbar.message}
        </AlertComponent>
      </Snackbar>
    </>
  );
}

GenerationsCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
  generations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      jobTitle: PropTypes.string.isRequired,
      content: PropTypes.object.isRequired,
      jobDetails: PropTypes.object,
      timestamp: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.shape({
          toDate: PropTypes.func.isRequired,
        }),
        PropTypes.shape({
          seconds: PropTypes.number.isRequired,
        }),
        PropTypes.string,
      ]).isRequired, // Firestore timestamp or Date string
      progress: PropTypes.number,
      status: PropTypes.string,
    })
  ).isRequired,
  onSelectGeneration: PropTypes.func.isRequired,
  onEditGeneration: PropTypes.func.isRequired,
  onDeleteGeneration: PropTypes.func.isRequired,
  onUpdateProgress: PropTypes.func.isRequired,
  onAddGeneration: PropTypes.func, // optional
};

export default withStyles(styles)(GenerationsCalendar);
